<template>
  <footer :class="`z-0 items-center flex bg-black justify-between bg-ultra-light-grey ${footerStatus}`">
    <audio ref="audio-player" style="display: none">
      <source src="@/assets/futuristic.wav" type="audio/wav" />
      Your browser does not support the audio element.
    </audio>
    <div :class="`z-0 py-6 flex min-h-[130px] items-center ml-6 ${setOpacity}`">
      <img src="~@/assets/icons/ico-valid.svg" alt="Success" v-if="footerStatus === 'success'">
      <img src="~@/assets/icons/ico-error.svg" alt="Error" v-if="footerStatus === 'error'">
      <div class="flex flex-col w-auto ml-4 justify-start text-left status-messages">
        <span class="grand-text-bold">{{ messageStatus.title}}</span>
        <span v-if="messageStatus.body" class="body-text mr-6">{{ messageStatus.body}}</span>
        <span v-if="activity && activity.correction" class="body-text mr-6">{{ activity.correction }}</span>
      </div>
    </div>
    <ShadowButton class="pr-5" :theme="dynaTheme" @click="clickAction()" v-if="footerStatus !== 'disabled'">{{buttonMessage}}</ShadowButton>
  </footer>
</template>

<script>
import {mapState} from "vuex";
import ShadowButton from "../ShadowButton";
import { difference } from 'lodash';

const {GameType} = require("../../constants/game-config");
export default {
  emits:['action', 'result'],
  components: {ShadowButton},
  mounted() {
    this.init()
  },
  data() {
    return {
      askForChecking: false,
      askForResult: false,
      statusMessage: {title:'', body:''},
      resultSent: false
    }
  },
  props:{
    activity:{
      type: Object
    },
    message: {
      type: Object,
      default: function(){ return {title: '', body: ''} }
    },
    singleActivityMode:{
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState({
      currentTheme: state => state.currentTheme
    }),
    dynaTheme(){
      if(this.footerStatus === 'error') {
        return 'error-light-red'
      }else if(this.footerStatus === 'success'){
        if(!this.askForResult)
          this.clickAction();
        this.startAudio();
        return 'success-light-green'
      }else{
        return `${this.currentTheme}-ultra-light-grey`
      }
    },
    buttonMessage(){
      if(this.footerStatus === 'error') {
        if(this.askForChecking && ! this.askForResult)  {
          return this.$t.buttons.ask_for_result
        }
        return this.$t.buttons.bouton_continuer
      }else if(this.askForChecking || this.footerStatus === 'success'){
        if(this.singleActivityMode){
          return this.$t.buttons.bouton_retry
        }else{
          return this.$t.buttons.bouton_continuer
        }
      }else{
        return  this.$t.buttons.bouton_validate
      }
    },
    setOpacity() {
      return this.footerStatus === 'error' || this.footerStatus === 'success' ? 'opacity-100' : 'opacity-0'
    },
    messageStatus() {
      if(this.askForChecking){
        return this.footerStatus === 'success'?
            {title: this.$t.footer.right_answer, body: `${this.$t.footer.body_prefix}
              ${this.activity.scores && this.activity.scores.length  > 0 ? `${this.activity.scores[0].score} ${this.$t.footer.body_before_suffix}${this.activity.scores[0].score > 1 ? `${this.$t.footer.body_plural_suffix}` : ''} ${this.$t.footer.body_after_suffix} ${this.activity.scores[0].type.name}`: ''}` }:
            {title: this.$t.footer.wrong_answer, body: ''}
        }
      return {title:'', body:''}
    },
    footerStatus() {
        if(this.activity && this.activity.userAnswer){
          if((this.activity && this.activity.userAnswer.length) > 0 && !this.askForChecking) {
            return 'default'
          }else if(this.activity && this.activity.userAnswer.length > 0 && this.askForChecking){

            if (this.activity.type === GameType.DRAGDROPTEXT || this.activity.type === GameType.DRAGDROP) {
              return (this.isArrayEquals(this.activity.userAnswer[0], this.activity.question.answers[0])
              && this.isArrayEquals(this.activity.userAnswer[1], this.activity.question.answers[1]))
                  ? 'success' : this.askForChecking && this.askForResult ? 'default' : 'error'
            }else if (this.activity.type === GameType.VIDEOSIMPLECARDS) {
                 return  'success'
            }else if (this.activity.type === GameType.VIDEOANSWER || this.activity.type === GameType.VIDEOREPONDEUR)
                 return  'success'
            else{
              return this.isArrayEquals(this.activity.userAnswer, this.activity.question.answers)
                ? 'success' : this.askForChecking && this.askForResult ? 'default' : 'error'
            }

          }else if(!this.activity){
            return 'disabled';
          }
          return 'disabled'
      }
      return 'disabled'
    }
  },
  methods: {
    clickAction() {
      if(!this.askForChecking) {
        this.askForChecking = true
        this.resultSent = true
        this.$emit('action', 'lockGame');
        this.$emit('result', this.footerStatus)
      } else if(!this.askForResult) {
        this.askForResult = true;
        this.$emit('action', 'viewResult');
      } else {
        if(this.singleActivityMode){
          this.$emit('action', 'refresh')
          this.reset()
        }else{
          console.log('EMITING ACTION = NEXT')
          this.$emit('action', 'next')
        }
      }
    },
    isArrayEquals(arr1, arr2){
      return difference(arr1, arr2).length === 0 && difference(arr2, arr1).length === 0
    },
    reset(){
      this.askForChecking = this.askForResult = false;
    },
    init(){
        this.$nextTick(() => {
          var audio = this.$refs['audio-player'];
          audio.addEventListener(
              "canplay",
              function() {
                this.audioLoaded=true;
                this.isPlaying = false;
              }.bind(this)
          );

          this.$watch("isPlaying",() => {
            if(this.isPlaying) {
              var audio=this.$refs['audio-player'];
              if(!this.listenerActive) {
                this.listenerActive=true;
                audio.addEventListener("timeupdate",this.playBackListeners);
              }
            }
          });
        });
    },
    playBackListeners() {
      const audio = this.$refs["audio-player"];
      audio.addEventListener("ended", this.audioendListener);
    },
    audioendListener() {
      this.isPlaying = false;
      this.cleanupListeners();
    },
    startAudio() {
      this.isPlaying = true;
      const audio = this.$refs["audio-player"];
      audio.play();
    },
    cleanupListeners() {
      const audio = this.$refs["audio-player"];
      audio.removeEventListener("timeupdate", this.playbackListener);
      audio.removeEventListener("ended", this.audioendListener);
    },
  },
  watch:{
    activity: {
      handler(activity){
        if (activity) {
          this.resultSent = false
          this.askForChecking = this.askForResult = false;
          
          if (activity.type) {
            this.askForChecking = [GameType.PHOTO, GameType.VIDEOSIMPLECARDS, GameType.VIDEOANSWER, GameType.VIDEOREPONDEUR].includes(this.activity.type);
            this.askForResult = [GameType.VIDEOSIMPLECARDS].includes(this.activity.type);
          }
        }
      },
      immediate: true
    },
    footerStatus(status){
      if (!this.resultSent && this.askForChecking && status == 'success'){
        console.log('SENDING RESULT')
        this.$emit("result", this.footerStatus)
      }
    }
  },
}
</script>

<style lang="scss" scoped>
    footer{
        transition: background-color 1.5s, border-color 1.5s;
         div:first-child{
            transition: opacity 1.5s
          }
    }
    .disabled{
      @apply opacity-0
    }
    .default{
      .status-messages {
        @apply hidden
      }
      @apply border-grey-light border-t opacity-100
    }
    .error{
      @apply border-red border-t bg-light-red text-red opacity-100
    }
    .success{
      @apply border-green border-t bg-light-green text-green opacity-100
    }
</style>
