<template>
  <div class="flex" :class="currentTheme">
    <article class="w-11/12">
      <!-- <header class="flex justify-between w-full items-center">
        <div>
          <p class="text-left huge-text-bold mb-2 text-dark-midnight-blue">{{activity.instruction}}</p>
          <p class="text-left body-text text mb-8 text-blue-grey">{{activity.help}}</p>
        </div>
        <div>
          <router-link :to="{ name: 'enquete/EnqueteWrapper', params: { noIntro: 'select' }  }"><ShadowButton theme="default-grey" width="50px">x</ShadowButton></router-link>
        </div>
      </header> -->
        <ul class="flex flex-wrap">
          <li v-for='q in currentQuestion.choices' :key="q" class="w-6/12 1480:4/12">
            <Actors :activityType="activity.type" :lockGame="lockGame" :correction="correction" :actor-pic="q.media ? q.media.path : ''" :answers="currentQuestion.answers" :value="q.id" :text="q.value" @formUpdate="formUpdate($event)"></Actors>
          </li>
        </ul>
    </article>

  </div>
</template>

<script>

import Actors from "../../components/Actors";
import {mapState} from "vuex";


export default {
  components: {Actors},
  mounted() {
    this.introOpen = true;
  },
  data() {
    return {
      introOpen: false,
      outroOpen: false
    }
  },
  props: {
    activity: {
      type: Object
    },
    actId:{
      type: [Number, String]
    },
    scenarioId:{
      type: [Number, String]
    },
    lockGame:{
      type: Boolean,
      default: false
    },
    correction:{
      type: Boolean,
      default: false
    }
  },
  computed: {
    currentQuestion() {
      return this.activity.question ? this.activity.question : undefined
    },
    ...mapState({
      currentTheme: state =>  state.currentTheme
    })
  },
  watch: {
    activity() {
      this.introOpen = true
      this.outroOpen = false

      this.$store.dispatch('enquete/resetUserAnswers',{ actId: this.actId, scenarioId: this.scenarioId, activityId: this.activity.id})
    }
  },
  methods: {
    formUpdate(val) {
      this.$store.dispatch('enquete/setUserAnswer',{ actId: this.actId, scenarioId: this.scenarioId, activityId: this.activity.id,  valuestate: val})
    },
  }
}
</script>

<style lang="scss" scoped>

</style>
