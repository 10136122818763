<template>

  <!--narrative -->
  <div class="flex">
    <CustomModal bg-image="" :fullscreen="true" height="h-screen" width="w-screen" :open="!!currentNarrative || !!currentNarrativeWelcomeHome" :bgColor="'bg-midnight-blue'" :shadow="false"  :rounded="false">
       <EnqueteNarrative @nextstep="getNextGame" slide="top"  :narrative="currentNarrative || currentNarrativeWelcomeHome" v-if="currentNarrative || currentNarrativeWelcomeHome"></EnqueteNarrative>
    </CustomModal>

  </div>

    <HeaderCoproxi></HeaderCoproxi>
  <div v-if="loaded" class="relative overflow-hidden bg-no-repeat bg-midnight-blue bg-left-top bg-auto homecontainer">
    <div class="w-full relative 1920:w-screen w-[1920px] text-left" >
          <div id="game1" class="absolute w-[20%] top-[70%] left-[8%]" @click="loadNarratives(courses[0]?.modules[0])">

            <div class="done flex items-center justify-center" v-if="missionFinished.includes(courses[0]?.modules[0].id)">
              <span class="">
                <img src="~@/assets/icons/ico-check-valid-quest.svg" class="pt-7 rounded-full h-12 w-12 shadow-md">
                <p class="align-end justify-self pt-6">Refaire</p>
              </span>
            </div>
            <div class="circle rounded-full shadow-md" v-if="!missionFinished.includes(courses[0]?.modules[0].id)"></div>
            <div class="desc bg-white rounded-md shadow-md p-5">
              <p class="body-text-bold text-midnight-blue">{{ courses[0]?.modules[0].title }}                     {{}}
              </p>
            </div>
          </div>

          <div id="game2" class="absolute w-[20%] top-[18%] left-[22%]"  @click="loadNarratives(courses[0]?.modules[1])">

            <div class="done flex items-center justify-center" v-if="missionFinished.includes(courses[0]?.modules[1].id)">
              <span class="">
                <img src="~@/assets/icons/ico-check-valid-quest.svg" class="pt-7 rounded-full h-12 w-12 shadow-md">
                <p class="align-end justify-self pt-6">Refaire</p>
              </span>
            </div>

            <div class="circle rounded-full shadow-md" v-if="!missionFinished.includes(courses[0]?.modules[1].id)"></div>
            <div class="desc bg-white rounded-md shadow-md p-5">
              <p class="body-text-bold text-midnight-blue">{{ courses[0]?.modules[1].title }}
              </p>
            </div>
          </div>

          <div id="game3" class="absolute  w-[20%] top-[50%] left-[22%]"  @click="loadNarratives(courses[0]?.modules[2])">

            <div class="done flex items-center justify-center" v-if="missionFinished.includes(courses[0]?.modules[2].id)">
              <span class="">
                <img src="~@/assets/icons/ico-check-valid-quest.svg" class="pt-7 rounded-full h-12 w-12 shadow-md">
                <p class="align-end justify-self pt-6">Refaire</p>
              </span>
            </div>

            <div class="circle rounded-full shadow-md" v-if="!missionFinished.includes(courses[0]?.modules[2].id)"></div>
            <div class="desc bg-white rounded-md shadow-md p-5">
              <p class="body-text-bold text-midnight-blue">{{ courses[0]?.modules[2].title }}
              </p>
            </div>
          </div>

          <div id="game4" class="absolute w-[20%] top-[58%] left-[42%]"  @click="loadNarratives(courses[0]?.modules[3])">

            <div class="done flex items-center justify-center" v-if="missionFinished.includes(courses[0]?.modules[3].id)">
              <span class="">
                <img src="~@/assets/icons/ico-check-valid-quest.svg" class="pt-7 rounded-full h-12 w-12 shadow-md">
                <p class="align-end justify-self pt-6">Refaire</p>
              </span>
            </div>

            <div class="circle rounded-full shadow-md" v-if="!missionFinished.includes(courses[0]?.modules[3].id)"></div>
            <div class="desc bg-white rounded-md shadow-md p-5">
              <p class="body-text-bold text-midnight-blue">{{ courses[0]?.modules[3].title }}
              </p>
            </div>
          </div>
          <img src="@/assets/pics/buildings.jpg" class="1920:w-screen w-[1920px]">

    </div>
        <div class="w-5/12 hidden 1480:block absolute 1480:w-3/12 p-6 top-[0%] right-[0%] h-[500px] mt-20 mr-5 rounded-md bg-midnight-blue">
          <ScoreBox></ScoreBox>
        </div>
  </div>

</template>

<script>
import HeaderCoproxi from "../components/HeaderCoproxi";
import ScoreBox from "../components/score-box/ScoreBox";
import { IsFirstLevelFinished } from '@/composables/user_log';
import {mapState} from "vuex";
import {CourseType} from "../constants/game-config";
import {client as apolloClient} from '../config/apolloClient'
import CustomModal from "../components/CustomModal"
import EnqueteNarrative from "@/views/enquete/EnqueteNarrative"
import {parseNarrative} from "../utils/apollo-parser";
import {parseNarrativeFromId} from "../utils/apollo-parser";

const {useRouter} = require("vue-router");

const {useRoute} = require("vue-router");


export default {
  emits: ["nextstep"],
  components: {
    HeaderCoproxi, ScoreBox, CustomModal, EnqueteNarrative
  },
  props: {
    fromMain:{
      type: String,
      default: '',
      required: false
    },
    bypass:{
      type: String,
      default: '',
      required: false
    },
  },
  data: () => ({
    loaded: false,
    narrativesIntro: [],
    narrativeIndex: -1,
    narrativeWelcomeHome: [],
    narrativeWelcomeIndex: 0,
    module: 0
  }),
  computed: {
    currentNarrative(){
      return this.narrativesIntro && this.narrativeIndex > -1 ? this.narrativesIntro[this.narrativeIndex] : undefined
    },
    currentNarrativeWelcomeHome(){
      return this.narrativeWelcomeHome && this.narrativeWelcomeIndex > -1 ? this.narrativeWelcomeHome[this.narrativeWelcomeIndex] : undefined
    },

    ...mapState({
      courses: state => state.course.courses,
      currentTheme: state => state.currentTheme,
      actsFinished: state => state.gamestatus.actsFinished,
      missionFinished: state => state.gamestatus.missionFinished,
      introopen: state => state.gamestatus.introNiv1Done,

    })
  },
  async mounted() {
    const route = useRoute()
    const router = useRouter()
    const byPass = route.params.bypass

    this.$store.dispatch('setTheme', 'midnight-blue')

    await this.$store.dispatch('course/getCourses', CourseType.MISSION )

    if (this.missionFinished.length === 4 && !byPass){
      this.$router.push({ name: 'enquete/EnqueteWrapper'})
    }

    if (IsFirstLevelFinished() && this.fromMain){
      this.$router.push({ name: 'enquete/EnqueteWrapper'})
    }

    if(!this.introopen && this.courses[0].metas['narrator_intro']) {
      const [narrative] = await Promise.all([parseNarrativeFromId(this.courses[0].metas['narrator_intro'])]);
      this.narrativeWelcomeHome = narrative
    }else{
      this.$store.dispatch('gamestatus/setIntroNiv1Done' , true)
    }
    this.loaded = true
  },
  methods: {
    getNextGame() {
      if(!this.introopen) {
        if(this.narrativeWelcomeHome.length === this.narrativeWelcomeIndex+1){
          this.$store.dispatch('gamestatus/setIntroNiv1Done' , true)
          this.narrativeWelcomeHome = [];
          this.narrativeWelcomeIndex = -1;
        }else
          this.narrativeWelcomeIndex++;
      } else{
        if(this.narrativesIntro.length === this.narrativeIndex+1){
          this.loadQuest(this.module)
        }else
          this.narrativeIndex++;
        }
    },
    async loadNarratives(module){
      this.module = module

      this.narrativesIntro = []
      // console.log('MODULE', module);

      if(module.metas.narrator_intro) {
        await apolloClient.query({
          query: require('@/gql/narratives/narrative_by_pk.gql'),
          variables: {
            UUID: module.metas.narrator_intro
          }
        }).then(async resultQuery => {
          if (resultQuery.data.item) {
            const [narrative] = await Promise.all([parseNarrative(resultQuery.data.item)])
            this.narrativesIntro = narrative
          }
        })
      }

      this.getNextGame()

      /*for(const meta of module.metas.filter( m => m.type.slug.includes('narrator'))) {
        await apolloClient.query({
          query: require('@/gql/narratives/narrative_by_pk.gql'),
          variables: {
            UUID: meta.value
          }
        }).then(async resultQuery => {
          console.log('NarrativeQUERYY', resultQuery)
          const [narrative] = await Promise.all([parseNarrative(resultQuery.data.item)])
          narrative.type = meta.type.slug;
          narratives.push(narrative)
        })
      }*/


    },
    async loadQuest(currentModule) {
      await this.$store.dispatch('gamestatus/setGame', {currentSequence: currentModule.sequences[0].id, currentModule: currentModule.id, currentCourseType: this.courses[0].type.slug})
      this.$router.push({name: 'games/GameWrapper'});
    }
  }
}
</script>

<style lang="scss" scoped>
  #game1, #game2, #game3, #game4 {
    .desc {
      position:relative;
      top: -90px;
      box-shadow: 0px 0px 15px black ;
    }
    >img, div{
      box-shadow: 0px 0px 15px black ;
    }
    .circle{
      &:after {
        content: "";
        display: block;
        padding-bottom: 100%;
      }
      @apply w-[70%] max-w-[200px] absolute
    }
    @apply  absolute flex flex-col justify-center items-center text-center cursor-pointer
  }
  .done{
    &:after {
      content: "";
      display: block;
      padding-bottom: 100%;
    }
    @apply rounded-full absolute w-[70%] max-w-[200px] max-h-[200px] border-4 border-green bg-md-green text-green flex items-center justify-center
  }

</style>
