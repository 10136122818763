<template>
  <div class="flex" :class="currentTheme">
    <article class="w-11/12">
      <!-- <header class="flex justify-between w-full items-center">
        <div>
          <p class="text-left huge-text-bold mb-2 text-dark-midnight-blue">{{activity.instruction}}</p>
          <p class="text-left body-text text mb-8 text-blue-grey">{{activity.help}}</p>
        </div>
        <div>
          <ShadowButton theme="default-grey" width="50px" @click="$emit('close')">x</ShadowButton>
        </div>
      </header> -->
      <div class="flex justify-between items-center">
        <div class="drop-container w-4/12">
          <p class="font-bold">{{activity.question.containers[0]}}</p>
          <ul class="drag-drop-zone" id="container-left" ref="containerLeft">
            <li class="drop-here drop-zone"  v-for='choice of activity.question.choices' :key="choice"></li>
          </ul>
        </div>
        <div class="items w-4/12 drag-drop-zone">
          <div v-for='choice of activity.question.choices' :key="choice" class="drop-zone bg-grey-light h-[108px] mb-6 rounded-md">
            <div class="drag-me" :data-answer-id="choice.id" :class="resultClass || resultValues[choice.id]">
              <ShadowButton width="130px" height="130px" :theme="resultClass ? `${resultClass}-reverse` : (resultValues[choice.id] ? `${resultValues[choice.id]}-reverse` : 'light-grey-no-press')" >
                <div class="flex justify-center">
                  <img :src="mediaserverurl + choice.value.path">
                </div>
              </ShadowButton>
            </div>
          </div>
        </div>
        <div class=" drop-container w-4/12">
          <p class="font-bold">{{activity.question.containers[1]}}</p>
          <ul class="drag-drop-zone"  id="container-right"  ref="containerRight">
            <li class="drop-here drop-zone" v-for='l in activity.question.choices.length ' :key="l"></li>
          </ul>
        </div>
      </div>
    </article>
  </div>
</template>

<script>

import {mapState} from "vuex";
import ShadowButton from "../../components/ShadowButton";
import { Droppable } from '@shopify/draggable';

export default {
  components: {ShadowButton},
  mounted() {
    this.introOpen = true;
    this.$refs.containerLeft.childNodes.forEach(c => console.log(c));

    const containers = document.querySelectorAll('.drag-drop-zone')
    const left = [];
    const right = [];
   this.droppable = new Droppable(containers, {
      draggable: '.drag-me',
      dropzone: '.drop-zone',
      classes: {
        'source:dragging': 'opacity-0',
        'source:original': 'opacity-0',
        'mirror': 'drag-item',
      }
    });
    this.droppable.on('mirror:created', function(e){
      e.data.mirror.querySelector('div').style.transform = "rotate(0deg)";
      e.data.mirror.querySelector('div').style.transform = "rotate(10deg)";
    });

    this.droppable.on('drag:stop', (evt) => {
      const position = evt.source.parentNode.parentNode.id
      const id = evt.originalSource.getAttribute('data-answer-id');

      if(position === 'container-left') {
        if(left.find( v => v === id)){
          return
        }
        else{
          if(right.find(v => v === id)){
            right.splice(right.indexOf(id), 1)
          }
          left.push(id)
        }
      }
      else if(position === 'container-right') {
        if(right.find( v => v === id)){
          return
        }
        else{
          if(left.find(v => v === id)){
            left.splice(left.indexOf(id), 1)
          }
          right.push(id)
        }
      } else{
        if(left.find(v => v === id)){
          left.splice(left.indexOf(id), 1)
        }
        if(right.find(v => v === id)){
          right.splice(right.indexOf(id), 1)
        }
      }
      if(left.length + right.length === 3){
        this.formUpdate({checked: true, reset:true, value: [left,right]})
        document.querySelectorAll('[draggable="true"]').forEach( v => v.setAttribute("draggable", "false"))
        console.log(left, right)
      }else{
        this.resetAnswers()
      }

    });

  },
  data() {
    return {
      mediaserverurl: process.env.VUE_APP_MEDIA_URL,
      introOpen: false,
      outroOpen: false,
      droppable:undefined,
      error:undefined,
      resultClass: null,
      dataContainers: {
        'left': [],
        'right': []
      },
      resultValues: {}
    }
  },
  props: {
    activity: {
      type: Object
    },
    actId:{
      type: [Number, String]
    },
    scenarioId:{
      type: [Number, String]
    },
    lockGame:{
      type: Boolean,
      default: false
    },
    correction:{
      type: Boolean,
      default: false
    },
    result:{
      type: String,
      default: undefined
    },

  },
  computed: {
    currentQuestion() {
      return this.activity.question ? this.activity.question : undefined
    },
    ...mapState({
      currentTheme: state =>  state.currentTheme
    })
  },
  methods: {
    formUpdate(val) {
      this.$store.dispatch('enquete/setUserAnswer',{ actId: this.actId, scenarioId: this.scenarioId, activityId: this.activity.id,  valuestate: val})
    },
    resetAnswers() {
      this.$store.dispatch('enquete/resetUserAnswers',{ actId: this.actId, scenarioId: this.scenarioId, activityId: this.activity.id})
    },
    giveAnswers: function () {
      const elements = document.querySelectorAll('.drag-me')
      const liLeft = this.$refs.containerLeft.querySelectorAll('.drop-here')
      const liRight = this.$refs.containerRight.querySelectorAll('.drop-here')

      liLeft.forEach( c => c.textContent = '')
      for (let i = 0; i < this.activity.question.answers[0].length; i++) {
        for (const element of elements) {
          if (element.getAttribute('data-answer-id') === this.activity.question.answers[0][i]) {
            for (const li of liLeft) {
              if (!li.hasChildNodes()) {
                li.append(element)
                break;
              }
            }
          }
        }
      }
      liRight.forEach( c => c.textContent = '')
      for (let i = 0; i < this.activity.question.answers[1].length; i++) {
        for (const element of elements) {
          if (element.getAttribute('data-answer-id') === this.activity.question.answers[1][i]) {
            for (const li of liRight) {
              if (!li.hasChildNodes()) {
                li.append(element)
                break;
              }
            }
          }
        }
      }
    }
  },
  watch:{
    lockGame() {
      if (this.droppable) {
        this.droppable.destroy();
      }
    },
    result(){
      this.dataContainers['left'].forEach(answer => {
        if (this.currentQuestion.answers[0].includes(answer)){
          this.resultValues[answer] = 'success';
        } else {
          this.resultValues[answer] = 'error';
        }
      })

      this.dataContainers['right'].forEach(answer => {
        if (this.currentQuestion.answers[1].includes(answer)){
          this.resultValues[answer] = 'success';
        } else {
          this.resultValues[answer] = 'error';
        }
      })
    },
    correction(correction) {
      if (correction) {
        this.resultClass = 'success'
        this.giveAnswers()
      } else {
        this.resultClass = null
        this.resetAnswers()
      }
    },
    activity(activity) {
      // Reset component states
      this.introOpen = false
      this.outroOpen = false
      this.droppable = undefined
      this.error = undefined

      this.$store.dispatch('enquete/resetUserAnswers',{ actId: this.actId, scenarioId: this.scenarioId, activityId: this.activity.id})

      if (activity) {
        this.introOpen = true;
        this.$refs.containerLeft.childNodes.forEach(c => console.log(c));

        if (this.droppable) {
          this.droppable.destroy();
        }

        const containers = document.querySelectorAll('.drag-drop-zone')
        const left = [];
        const right = [];
        this.droppable = new Droppable(containers, {
          draggable: '.drag-me',
          dropzone: '.drop-zone',
          classes: {
            'source:dragging': 'opacity-0',
            'source:original': 'opacity-0',
            'mirror': 'drag-item',
          }
        });
        this.droppable.on('mirror:created', function(e){
          e.data.mirror.querySelector('div').style.transform = "rotate(0deg)";
          e.data.mirror.querySelector('div').style.transform = "rotate(10deg)";
        });

        this.droppable.on('drag:stop', (evt) => {
          const position = evt.source.parentNode.parentNode.id
          const id = evt.originalSource.getAttribute('data-answer-id');

          if(position === 'container-left') {
            if(left.find( v => v === id)){
              return
            }
            else{
              if(right.find(v => v === id)){
                right.splice(right.indexOf(id), 1)
              }
              left.push(id)
            }
          }
          else if(position === 'container-right') {
            if(right.find( v => v === id)){
              return
            }
            else{
              if(left.find(v => v === id)){
                left.splice(left.indexOf(id), 1)
              }
              right.push(id)
            }
          } else{
            if(left.find(v => v === id)){
              left.splice(left.indexOf(id), 1)
            }
            if(right.find(v => v === id)){
              right.splice(right.indexOf(id), 1)
            }
          }
          this.dataContainers['left'] = left;
          this.dataContainers['right'] = right;
          if(left.length + right.length === 3){
            this.formUpdate({checked: true, reset:true, value: [left,right]})
            document.querySelectorAll('[draggable="true"]').forEach( v => v.setAttribute("draggable", "false"))
            console.log(left, right)
          }else{
            this.resetAnswers()
          }

        });
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .draggable-source{
    background-color:red;
    border: 1px solid red;
  }
  .drop-zone {
    height: 110px;
    width: 130px;
    transition: transform .5s;

    &.drag {
      transform: rotateX(25deg);
    }

    @apply block border-2 border-dashed rounded-xl border-grey-medium
  }
  .drop-container {
    ul{
      li{
       .drag-me{
          transform: translateX(-5px) translateY(-5px);
        }

        @apply mb-5
      }
      @apply flex flex-col justify-center items-center p-5
    }
    @apply border-2 rounded-xl border-light-grey bg-grey-ultralight
  }
  .items {
    .drop-zone{
      border: 3px solid white;
    }
    @apply w-2/12 flex flex-col items-center justify-center
  }
  .drag {
    transform: rotateX(25deg);
  }
  .draggable--original{
    @apply bg-red text-red border-red opacity-5
  }
</style>
