<template>
  <div :class="`${cbTheme} flex w-full h-full cursor-pointer mb-5 items-stretch pr-5`" @click="updateStatus()">
    <ShadowButton width="100%" :disabled="globalTheme ? isChecked : false" :selected="isChecked" :status="cbTheme" :theme="globalTheme ? globalTheme : `ultra-light-grey-blue-quest`">
      <div :class="['h-full', vertical ? `flex-col justify-end` : 'justify-start' , `flex   items-center body-text-bold `]">
        <div :class="[vertical ? `flex-col` : '' ,`flex  items-center`]">
          <img v-if="actorPic" :src="mediaserverurl+actorPic" alt="Jo jo" :class="vertical ? 'w-28 ':'w-20'">
          <div class="content body-text-bold mx-2" :class="{ 'text-left':activityType == 'quiz_picture' }">
            {{text}}
            {{textComplementary ? `<br> ${textComplementary}` : ''}}
          </div>
        </div>
        <div :class="[activityType === 'quiz_picture' ? 'ml-auto' : '']">
          <Checkbox v-if="activityType == 'quiz_picture' && cbTheme !== 'cb-success' && cbTheme !== 'cb-error'" :checked="isChecked" :theme="cbTheme" :text="''" ></Checkbox>
          <img :src="require('../assets/icons/ico-check-'+currentTheme+'.svg')" alt="checked" v-if="activityType !== 'quiz_picture' && isChecked && !vertical && (cbTheme !== 'cb-success' && cbTheme !== 'cb-error')">
          <img :src="require('../assets/icons/icon-close.svg')" alt="checked" v-if="cbTheme==='cb-error' && !vertical">
          <img :src="require('../assets/icons/ico-check-valid-quest.svg')" alt="checked" v-if="cbTheme==='cb-success' && !vertical">
          <img class="bg-green h-9 p-1 w-9 relative top-7 rounded-full" :src="require('../assets/icons/ico-check-white.svg')" alt="checked" v-if="isChecked && vertical">
          <div v-if="activityType !== 'quiz_picture'">
            <p class="link text-blue-grey" v-if="!isChecked && cbTheme!=='cb-error' ">
              {{vertical ? $t.videos.bouton_interroger : $t.gameplay_classic.bouton_selectionner}}
            </p>
          </div>
        </div>
      </div>
    </ShadowButton>
  </div>
</template>

<script>
import ShadowButton from "./ShadowButton";
import Checkbox from "./Checkbox.vue";
import {mapState} from "vuex";

export default {
  components: {ShadowButton, Checkbox},
  emits: ["formUpdate"],

  props: {
    theme: {
      type: String,
      default: undefined
    },
    text: {
      type: String,
      default: ''
    },
    textComplementary: {
      type: String,
      default: undefined
    },
    actorPic: {
      type: String,
      default: null
    },
    value: {
      type: [Number, String],
      default: 0
    },
    checked: {
      type: Boolean,
      default: null
    },
    answers: {
      type: Array,
      default: function() {return []}
    },
    lockGame: {
      type: Boolean,
      default: false,
    },
    correction: {
      type: Boolean,
      default: false,
    },
    vertical: {
      type: Boolean,
      default: false,
    },
    'btnDisabled': {
      type: Boolean,
      default: true,
    },
    activityType: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      'mediaserverurl': process.env.VUE_APP_MEDIA_URL,
      icons: {
        'aide': require('@/assets/icons/icon-aide.svg'),
        'download': require('@/assets/icons/icon-download.png'),
        'down-arrow-midnight': require('@/assets/icons/icon-fleche-bas-midnight.png'),
      },
      isChecked: false,
      cbTheme: this.getClass(),
      globalTheme: undefined
    }
  },
  computed: mapState({
    currentTheme: state => state.currentTheme
  }),
  methods:{
    updateStatus() {
      if(!this.lockGame){
        this.isChecked = (this.checked !== null ? this.checked : !this.isChecked)
        this.globalTheme  = this.vertical && this.isChecked ? 'success-light-green' : undefined
        this.cbTheme = this.globalTheme ?  undefined :this.getClass()
        this.$emit('formUpdate', {checked: this.isChecked, value: this.value})
      }
    },
    getClass(){
      return this.isChecked ? 'cb-active' : 'cb-default'
    }
  },
  watch: {
    lockGame () {
      if (this.vertical)
        return

      if(this.isChecked){
        if (this.answers.includes(this.value)) {
          this.cbTheme = 'cb-success'
          this.isChecked = true;
        }else{
          this.cbTheme = 'cb-error'
          this.isChecked = false;
        }
      }
    },
    correction(){
      if (this.vertical)
        return

      if (this.answers.includes(this.value)) {
        this.cbTheme = 'cb-success'
        this.isChecked = true;
      }else{
        this.cbTheme = 'cb-default'
        this.isChecked = false;
      }
    },
    checked(){
      this.updateStatus();
    }
  }
}
</script>

<style lang="scss" scoped>
  .shadow-button {
    &:deep(button) {
      height: 90% !important;
    }

    &:deep(span) {
      height: 100%;
    }
  }
  .cb-active {
    .content{
      @apply text-blue-quest-light
    }
    @apply border-blue-quest-light
  }
  .cb-active {
    .content{
      @apply text-blue-quest-light
    }
  }
  .cb-active {
    .content{
      @apply text-blue-quest-light
    }
  }

  .cb-error {
    .cb-wrapper {
      .cb-content{
        @apply bg-red
      }
      @apply  border-red
    }
  }
  .cb-success {
    .cb-wrapper {
      .cb-content{
        @apply bg-green
      }
      @apply  border-green
    }
  }
</style>
