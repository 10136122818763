<template>
  <div class="flex flex-col">
    <h1 class="mb-8 text-36 font-coustard-black">{{$t.login.password_oublier_title}}</h1>
    <p v-if="!isDone" class="body-text-bold mb-5">{{$t.login.password_oublier_instruction}}</p>
    <p v-else class="body-text-bold mb-5">{{$t.login.password_oublier_success}}</p>
    <form v-if="!isDone" class="flex flex-col" @submit.prevent="onSubmit">
      <input class="p-2 mb-8 border-solid border-2 border-black rounded" :placeholder="$t.placeholders.email" type="text" v-model="email">
      <ShadowButton isSubmit theme="default-blue-reverse" :disabled="!email && !isLoading">{{$t.login.password_oublier_send_button}}</ShadowButton>
    </form>
  </div>
</template>

<script>
import { useAuth } from '@/composables/auth'
export default {
  name: "PasswordForgot",
  data() {
    return {
      isLoading: false,
      isDone: false
    }
  },
  setup() {
    let {email, sendResetEmail } = useAuth()
    return  {
      email,
      sendResetEmail
    }
  },
  methods: {
    async onSubmit() {
      if (this.isLoading || this.isDone)
        return

      this.isLoading = true

      await this.sendResetEmail()
      
      this.isDone = true
      
      this.isLoading = false
    }
  }
}
</script>

<style>

</style>
