<template>
  <section class="no-access w-full h-screen flex justify-center items-center ">
    <div class="no-access-container bg-white h-[200px] rounded-mdg flex justify-center  rounded-md items-center">
      <div class="container flex justify-center items-center" >
        <p>{{ error }}</p>
      </div>
    </div>
  </section>
</template>

<script>
import { getState } from '@/composables/user_log';

export default {
  name: 'NoAccess',
  computed: {
    error() {
      const user = getState.value?.user

      if (!user) {
        return ''
      }

      const userGroup = user.groups?.[0]?.group
      const nowTimestamp = Date.now()

      if (user.disabled) {
        return 'Désolé votre accès à Coproxi est suspendu pour le moment.'
      } else if (userGroup) {
        if (userGroup.disabled) {
          return 'Désolé l\'accès à Coproxi pour votre groupe est suspendu pour le moment.'
        } else if (nowTimestamp < Date.parse(userGroup.start_date)) {
          return 'Désolé votre groupe n\'a pas encore accès à Coproxi.'
        } else if (nowTimestamp > Date.parse(userGroup.end_date)) {
          return 'Désolé votre groupe n\'a plus accès à Coproxi.'
        }
      } else {
        return 'Désolé vous n\'avez pas encore accès à Coproxi.'
      }

      return 'Désolé vous n\'avez plus ou pas encore accès à Coproxi.'
    }
  },
}
</script>

<style lang="scss">
.no-access {
  background: url('~@/assets/pics/buildings.jpg');
  background-size: cover;

  .no-access-container {
    transition: transform .5s cubic-bezier(.29,-.8,.67,1.09);;
    width: 600px;

    .container {
      width: 60%;
      height: 60%;      
    }
  }
}
</style>
