<template>
  <section class="login w-full h-screen flex justify-center items-center ">
    
    <div :style="translate" class="login-container bg-white h-[500px] rounded-mdg flex justify-center  rounded-md items-center">
      <div class="container flex justify-center items-center" >
        <Login v-if="component === 'login'" @done="done()" @change-component="changeComponent"></Login>
        <PasswordForgot v-else-if="component === 'password-forgot'"></PasswordForgot>
      </div>
      <!-- <div class="container flex flex-col">
        <h1 class="mb-2 text-2xl">{{$t.login.forgot}}</h1>
        <input class="p-2 mb-2 border-solid border-2 border-black rounded" :placeholder="$t.placeholders.email" type="text" v-model="email">
        <input class="p-2 mb-2 border-solid border-2 border-black rounded" :placeholder="$t.placeholders.password" type="text" v-model="password">
        <span class="text-sm mb-4 cursor-pointer hover:underline text-left">{{$t.login.password_oublier}}</span>
        <AppButton class="w-3/5 self-center" :disabled="!email || !password" :action="signIn" :text="$t.buttons.login"></AppButton>
      </div> -->
    </div>
  </section>
</template>

<script>
import {ref} from 'vue';
import Login from '@/components/auth/Login'
import PasswordForgot from '@/components/auth/PasswordForgot'
export default {
  name: 'Auth',
  components: { Login, PasswordForgot },
  setup() {
    const component = ref('login')
    const changeComponent = (comp) => {
      component.value = comp
    }
    return {
      component,
      changeComponent
    }
  },
  data() {
    return {
      translate : ""
    }
  },
  methods :{
    done(){
      this.translate = 'transform: translateY(-100vh)'
    }
  }
}
</script>

<style lang="scss">
.login {
  background: url('~@/assets/pics/buildings.jpg');
  background-size: cover;
  .login-container {
    transition: transform .5s cubic-bezier(.29,-.8,.67,1.09);;
    width: 600px;
    .container {
      width: 60%;
      height: 60%;      
    }
  }
  .error {
    color: #cc0000;
  }
}
</style>
