<template>
  <div :class="`${cbTheme} flex cursor-pointer items-center`" @click="updateStatus()">
    <div class="cb-wrapper rounded-[4px] h-[30px] min-h-[30px] min-w-[30px] w-[30px] border-solid border-2 bg-white flex items-center justify-center" >
      <img :src="require('../assets/icons/icon-close.svg')" alt="checked" v-if="cbTheme==='cb-error'">
      <div class="cb-content rounded-[4px] h-[16px] w-[16px]" v-else-if="isChecked"></div>
    </div>
    <p v-if="text" class="ml-3 body-text text-midnight-blue text-left" :class="{'font-bold': isChecked}">{{text}}</p>
  </div>
</template>

<script>
export default {
  emits: ["formUpdate"],

  props: {
    theme: {
      type: String,
      default: 'cb-default'
    },
    text: {
      type: String,
      default: "Valider"
    },
    value: {
      type: [Number, String],
      default: 0
    },
    checked: {
      type: Boolean,
      default: false
    },
    answers: {
      type: Array,
      default: function() {return []}
    },
    lockGame: {
      type: Boolean,
      default: false,
    },
    correction: {
      type: Boolean,
      default: false,
    },
    result:{
      type: String,
      default: undefined
    },
  },
  data() {
    return {
      icons: {
        'aide': require('@/assets/icons/icon-aide.svg'),
        'download': require('@/assets/icons/icon-download.png'),
        'down-arrow-midnight': require('@/assets/icons/icon-fleche-bas-midnight.png'),
      },
      isChecked: this.checked,
      cbTheme: this.getClass()
    }
  },
  methods:{
    updateStatus() {
      if(!this.lockGame){
        this.isChecked = !this.isChecked
        this.cbTheme = this.getClass()
        this.$emit('formUpdate',{checked: this.isChecked, value: this.value})
      }
    },
    getClass(){
      return this.isChecked ? 'cb-active' : 'cb-default'
    }
  },
  watch: {
    correction(correction) {
      if (!correction) {
        this.cbTheme = 'cb-default'
        this.isChecked = false;
      } else if (this.answers.includes(this.value)) {
        this.cbTheme = 'cb-success'
        this.isChecked = true;
      } else if (this.isChecked) {
        this.cbTheme = 'cb-error'
      }
    },
    result(result){
      if (this.isChecked) {
        if (this.answers.includes(this.value)){
          this.cbTheme = 'cb-success'
          this.isChecked = true;
        } else {
          this.cbTheme = 'cb-error'
        }
      }
    },
    checked(checked){
      this.isChecked = checked
    }
  }
}
</script>

<style lang="scss" scoped>
  .cb-default {
    .cb-wrapper {
      .cb-content{
        @apply bg-white;
      }
      @apply border-grey-light;
    }
  }
  .cb-active {
    .cb-wrapper {
      .cb-content{
        @apply bg-midnight-blue;
      }
      @apply border-grey-light;
    }
  }
  .cb-error {
    .cb-wrapper {
      .cb-content{
        @apply bg-red;
      }
      @apply  border-red;
    }

    .body-text{
      @apply text-red;
    }
  }
  .cb-success {
    .cb-wrapper {
      .cb-content{
        @apply bg-green;
      }
      @apply  border-green;
    }
    .body-text{
      @apply text-green;
    }
  }
</style>
