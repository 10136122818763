<template>
  <div v-if="video" @click="this.retry = false;startVideo()" class="video-box" :class="[isPlaying ? '' : `overlay`, ' relative '+this.classContainerTag + ' '+format+' '+status, isReady ? 'ready' : '']">
    <div class="hidden" ref="videoHack" @click="startVideo()"></div>
    <video controls ref="video-player" :class="this.classVideoTag" @canplay="isReady = true" @ended="videoendListener">
      <source :src="mediaserverurl+video" type="video/mp4" >
      Your browser does not support the video tag.
    </video>
    <slot></slot>
  </div>
</template>

<script>
export default {
  emits: ["action", 'elapsed', 'duration'],
  props: {
    video: {
      type: String,
      default: undefined
    },
    start: {
      type: Boolean,
      default: false
    },
    'classVideoTag':{
      type: String,
      default: ''
    },
    'classContainerTag':{
      type: String,
      default: ''
    },
    restart:{
      type: Boolean,
      default: false
    },
    format:{
      type: String,
      default: ''
    },
    actor: {
      type: String,
      default: ''
    },
    lockGame: {
      type: Boolean,
      default: false,
    },
    correction: {
      type: Boolean,
      default: false,
    },
    status: {
      type: String,
      default: ''
    },
    askForRetry:{
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      'mediaserverurl': process.env.VUE_APP_MEDIA_URL,
      'videoLoaded': false,
      'isPlaying': false,
      'listenerActive' : false,
      'retry': this.askForRetry,
      playbackTime: 0,
      isReady: false
    }
  },
  mounted() {
      this.$nextTick(() => {
            var video = this.$refs['video-player'];
            video.addEventListener(
                "canplay",
                async function () {
                  this.videoLoaded = true;
                  this.$emit('duration', video.duration)
                  setTimeout(() =>   this.eventFire(this.$refs.videoHack, 'click'), 100)
                }.bind(this)
            );
            this.$watch("isPlaying", () => {
              if (this.videoLoaded) {
                var video = this.$refs['video-player'];
                if (!this.listenerActive) {
                  this.listenerActive = true;
                  video.addEventListener("timeupdate", this.playBackListeners);
                }
              }
            });
        this.$watch("playbackTime",function() {
         this.$emit('elapsed', this.$refs['video-player'].currentTime)
        });
      });
  },
  watch: {
    restart(val){
      if(val){
        this.replayVideo();
      }
    },
    video(val){
      if(!val){
        this.cleanupListeners();
      }
    },
    askForRetry(val){
      if(val){
        const video = this.$refs["video-player"];
        this.retry = val;
        video.currentTime = video.duration - .01;
        console.log('RETRYYYYYY')
      }
    }
  },
  methods: {
    playBackListeners() {
      const video = this.$refs["video-player"];
      if(video){
        this.playbackTime = video.currentTime;
      }


    },
    videoendListener() {
      this.$emit('action','end')
      this.$emit('duration',0)
      this.isPlaying = false;

    },
    startVideo() {
        if (this.isPlaying)
          return

        const video = this.$refs["video-player"];
        this.$emit('action', 'start')
        this.$emit('duration', video.duration)

        this.isPlaying = true;
        video.play();
        if(this.retry){
          video.currentTime = video.duration - .1;
          this.retry = false;
        }
      },
    cleanupListeners() {
      const video = this.$refs["video-player"];
      if(video){
        video.removeEventListener("timeupdate", this.playBackListeners);
      }
    },
    replayVideo() {
      this.$emit('action','replay')
      const video = this.$refs["video-player"];
      if (!this.isPlaying){
        this.$emit('duration', video.duration)
        this.startVideo()
      }
     // this.restartMedia = false
    },
    eventFire(el, etype) {
      if (el.fireEvent) {
        el.fireEvent('on' + etype);
      } else {
        var evObj = document.createEvent('Events');
        evObj.initEvent(etype, true, false);
        el.dispatchEvent(evObj);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.video-box {
  max-height: 80%;
}

video {
  @apply relative;
}

.visio {
  @apply rounded-md border-2 border-yellow-light;
}

.ready {
  video {
    @apply max-h-full;
  }
}

.phone {
  @apply bg-yellow-light border-yellow-light flex items-center justify-center min-w-0 m-auto;
  border-style: solid;
  border-width: 8px;
  padding: 36px 0;
  height: 60vh;
  width: auto;
  border-radius: 40px;

  &:before  {
    @apply absolute bg-midnight-blue;
    content:'';
    width:100px;
    height: 10px;
    top: 10px;
    left: 0;
    right: 0;
    margin: auto;
    border-radius:10px;
  }
  
  &.error{
    @apply border-md-red bg-md-red;

    &:before {
      @apply bg-red;
    }
  }

  &.success{
    @apply border-md-green bg-md-green;

    &:before{
      @apply bg-green;
    }
  }

  &.overlay:after {
    top: 36px;
    bottom: 36px;
  }

  video {
    @apply max-h-full;
  }
}

.overlay:after  {
  content:'';
  display:block;
  position: absolute;
  top:0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: opacity 0.5s ease;
  opacity: 1;
  cursor: pointer;
  background: url("~@/assets/icons/illus-relancer.svg") no-repeat center center;
  @apply bg-midnight-blue bg-opacity-50
}


.audio {
  video{
    @apply bg-yellow-medium hidden
  }
  border: 9px solid;
  border-radius:50px;
  &.success{
    @apply border-green bg-md-green w-[270px] h-[500px] flex items-center text-green
  }
  @apply border-yellow-medium bg-midnight-blue-light w-[270px] h-[500px] flex items-center text-yellow
}
.audio:before  {
  content:'';
  display: flex;
  width:100px;
  position:absolute;
  transform: translateY(-230px) translateX(78px);
  height: 10px;
  border-radius:10px;
  @apply bg-yellow
}
.audio.error:before{
  @apply bg-red
}
.audio.success:before{
  @apply bg-green
}
/* Add padding and center-align text to the container */
.container {
  padding: 16px;
  text-align: center;
}

/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
}
/* Modal Content/Box */
.modal-content {
  box-shadow: 0px 0px 20px 5px gray;
  margin: 5% auto 15% auto; /* 5% from the top, 15% from the bottom and centered */
}
/* Clear floats */
.clearfix::after {
  content: "";
  clear: both;
  display: table;
}
#myModal{
  z-index:2;
}
/* Change styles for cancel button and delete button on extra small screens */
@media screen and (max-width: 300px) {
  .cancelbtn, .deletebtn {
    width: 100%;
  }
}
</style>
