<template>
  <div :class="`m-10 ${currentTheme}`" >
    <aside class="flex flex-col p-6 pl-8 ">
        <div class="rounded-10 justify-start flex flex-col ">
          <h4 class="info-text text-left text-light-grey"><span class="text-light-grey">A FAIRE</span></h4>
          <div>
            <div class="flex">
              <ul class="stepper small-body-text 1480:body-text-bold mt-5 text-14" ref="optionList" v-if="!singleActivityMode">
                <li :class="spanClass(scenario.id)" :data-id="scenario.id" v-for="(scenario, index) of act.scenarios" :key="scenario" @click="goToScenario(index, scenario.id)"><span class="text-left text-sm 1480:text-lg font-bold">{{ scenario.title }}</span></li>
              </ul>
            </div>
          </div>
        </div>
    </aside>
    <aside class="p-6 pl-8">
      <h4 class="info-text text-left">POINTS DE COMPETENCES</h4>
      <div>
        <SkillsProgressBar :theme="currentTheme" :min="minPointsNecessary('legal')" skill="juridique" v-if="maxPoints('legal')" :max="maxPoints('legal')" :value="currentPoints('legal')"></SkillsProgressBar>
        <SkillsProgressBar :theme="currentTheme" :min="minPointsNecessary('actor')" skill="copro" v-if="maxPoints('actor')" :max="maxPoints('actor')" :value="currentPoints('actor')"></SkillsProgressBar>
        <SkillsProgressBar :theme="currentTheme" :min="minPointsNecessary('quality')" skill="qualite" v-if="maxPoints('quality')" :max="maxPoints('quality')" :value="currentPoints('quality')"></SkillsProgressBar>
      </div>
    </aside>
  </div>
</template>

<script>
import {mapState} from "vuex";
import SkillsProgressBar from "../score-box/SkillsProgressBar";
import { totalLegalPoints, totalActorPoints, totalQualityPoints, maxPointsByModule, minPointsByModule } from '@/composables/user_log';

export default {
  emits:['scenarioChange'],
  components: {SkillsProgressBar},
  computed: {
    ...mapState({
      currentTheme: state => state.currentTheme,
    }),
    minPointsNecessary(){
      return type => {
        if (!this.moduleID || this.moduleID === -1) {
          return 0
        }
        return parseInt(this.minPointsByModule[this.moduleID][type])
      }
    },
    currentPoints(){
      return type => {
        if (!this.moduleID || this.moduleID === -1){
          switch (type) {
            case 'legal':
              return this.totalLegalPoints.totalScore
            case 'actor':
              return this.totalActorPoints.totalScore
            case 'quality':
              return this.totalQualityPoints.totalScore
          }
        } else {
          switch (type) {
            case 'legal':
              return this.totalLegalPoints[this.moduleID]
            case 'actor':
              return this.totalActorPoints[this.moduleID]
            case 'quality':
              return this.totalQualityPoints[this.moduleID]
          }
        }
        return 0
      }
    },
    maxPoints(){
      return type => {
        if (!this.moduleID || this.moduleID === -1){
          switch (type) {
            case 'legal':
              return this.totalLegalPoints.maxScore
          case 'actor':
            return this.totalActorPoints.maxScore
          case 'quality':
            return this.totalQualityPoints.maxScore
        }
        } else {
          if (typeof this.maxPointsByModule[this.moduleID] === "undefined" || typeof this.maxPointsByModule[this.moduleID][type] === "undefined")
            return 0
          return this.maxPointsByModule[this.moduleID][type]
        }
        return 0
      }
    },
  },
  setup () {
    return {
      totalLegalPoints,
      totalActorPoints,
      totalQualityPoints,
      maxPointsByModule,
      minPointsByModule
    }
  },
  methods: {
    spanClass(id) {
      if(this.$store.state.gamestatus.scenariosFinished.includes(id)){
        return 'link-bold'
      }
      else if(this.$store.state.gamestatus.currentScenario === id){
        return 'active'
      }
      else{
        return 'locked'
      }
    },
    goToScenario(index, id){
      if (this.$store.state.gamestatus.scenariosFinished.includes(id)){
        this.$emit("scenarioChange", index)
      }
    },
    
  },
  props:{
    singleActivityMode:{
      type: Boolean,
      default: false
    },
    act:{
      type: Object,
      default: function () {
        return {}
      }
    },
    activity:{
      type: Object,
      default: function () {
        return {}
      }
    },
    moduleID:{
      type: String,
      default: '',
      required: false
    }
  }

}
</script>

<style lang="scss" scoped>
.default{
  h4{
    @apply text-dark-midnight-blue
  }
  aside:first-child{
    li{
      &.active {
        span {
          @apply bg-white pl-2 pb-2 pt-1 pr-2 rounded-xl text-midnight-blue text-left font-bariol font-bold
        }
        @apply mt-5
      }
      &.locked {
        span{
          @apply text-midnight-blue
        }
        &:before {
          @apply bg-dark-midnight-blue
        }
        &:after {
          @apply bg-dark-midnight-blue
        }
      }
      @apply mt-5
    }

    @apply bg-midnight-blue-light rounded-xl text-grey-ultralight
  }
  aside:nth-child(2){
    @apply rounded-xl border-4 border-grey-medium text-dark-midnight-blue mt-10
  }

}
.midnight-blue{
  @extend .default

}
.blue-quest{
  h4{
    @apply text-blue-quest-dark
  }
    aside:first-child{
      li{
        &.active {
          span {
            @apply bg-white pl-2 pb-2 pt-1 pr-2 rounded-xl text-blue-quest-light text-left font-bariol font-bold
          }
          @apply mt-5
        }
        &.locked {
          span{
            @apply text-blue-quest-dark
          }
          &:before {
            @apply bg-blue-quest
          }
          &:after {
            @apply bg-blue-quest
          }
        }
        @apply mt-5
      }

      @apply bg-blue-quest-light rounded-xl text-blue-quest-ultralight
    }
    aside:nth-child(2){
      @apply rounded-xl border-4 border-blue-quest-lighter text-blue-quest-ultralight mt-10
    }
    @apply bg-blue-quest-ultralight
  }
  .yellow-quest{
    h4{
      @apply text-yellow-quest-dark
    }
    aside:first-child{
      li{
        &.active {
          span {
            @apply bg-white pl-2 pb-2 pt-1 pr-2 rounded-xl text-yellow-quest-light text-left font-bariol font-bold
          }
          @apply mt-5
        }
        &.locked {
          span{
            @apply text-yellow-quest-dark
          }
          &:before {
            @apply bg-yellow-quest
          }
          &:after {
            @apply bg-yellow-quest
          }
        }
        @apply mt-5
      }

      @apply bg-yellow-quest-light rounded-xl text-yellow-quest-ultralight
    }
    aside:nth-child(2){
      @apply rounded-xl border-4 border-yellow-quest-lighter text-yellow-quest-ultralight mt-10
    }
    @apply bg-yellow-quest-ultralight
  }
  .green-quest{
    h4{
      @apply text-green-quest-dark
    }
    aside:first-child{
      li{
        &.active {
          span {
            @apply bg-white pl-2 pb-2 pt-1 pr-2 rounded-xl text-green-quest-light text-left font-bariol  font-bold
          }
          @apply mt-5
        }
        &.locked {
          span{
            @apply text-green-quest-dark
          }
          &:before {
            @apply bg-green-quest
          }
          &:after {
            @apply bg-green-quest
          }
        }
        @apply mt-5
      }

      @apply bg-green-quest-light rounded-xl text-green-quest-ultralight
    }
    aside:nth-child(2){
      @apply rounded-xl border-4 border-green-quest-lighter text-green-quest-ultralight mt-10
    }
    @apply bg-green-quest-ultralight
  }
  .pink-quest{
    h4{
      @apply text-pink-quest-dark
    }
    aside:first-child{
      li{
        &.active {
          span {
            @apply bg-white pl-2 pb-2 pt-1 pr-2 rounded-xl text-pink-quest-light text-left font-bariol  font-bold
          }
          @apply mt-5
        }
        &.locked {
          span{
            @apply text-pink-quest-dark
          }
          &:before {
            @apply bg-pink-quest
          }
          &:after {
            @apply bg-pink-quest
          }
        }
        @apply mt-5
      }

      @apply bg-pink-quest-light rounded-xl text-pink-quest-ultralight
    }
    aside:nth-child(2){
      @apply rounded-xl border-4 border-pink-quest-lighter text-pink-quest-ultralight mt-10
    }
    @apply bg-pink-quest-ultralight
  }
  .stepper{
    li {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      text-align: right;
      gap: 12px;

      &:before {
        margin: 0;
        content: "";
        counter-increment: step;
        height: 12px;
        width: 12px;
        line-height: 30px;
        display: block;
        text-align: center;
        margin-top: 10px;
        border-radius: 50%;
        background-color: white;
        z-index: 1;
      }

      &:after {
        /* CSS for creating horizontal line*/
        content: "";
        position: absolute;
        height: 50px;
        width: 2px;
        background-color: #ddd;
        transform: translate(5px, -32px);
      }

      &:first-child{
        margin-top:0;
        &:after {
          content: none;
        }
      }
    }
  }
</style>
