<template>
  <div class="flex" v-if="currentQuestion">
    <article class="w-11/12">
      <!-- <header class="flex justify-between w-full items-start">
        <div>
          <p class="text-left huge-text-bold mb-2 text-dark-midnight-blue">{{activity.instruction}}</p>
          <p class="text-left body-text text mb-8 text-blue-grey">{{activity.help}}</p>
        </div>
        <div>
          <ShadowButton theme="default-grey" width="50px" @click="$emit('close')">x</ShadowButton>
        </div>
      </header> -->
        <ul>
          <li v-for='q in currentQuestion.choices' :key="q" class="mb-2">
            <Checkbox :lockGame="lockGame" :correction="correction" :result="result" :answers="currentQuestion.answers" :value="q.id" :text="q.value" @formUpdate="formUpdate($event)"></Checkbox>
          </li>
        </ul>
    </article>

  </div>
</template>

<script>

import Checkbox from "../../components/Checkbox";

export default {
  components: {Checkbox},
  mounted() {
    this.introOpen = true;
  },
  data() {
    return {
      introOpen: false,
      outroOpen: false
    }
  },
  props: {
    activity: {
      type: Object
    },
    actId:{
      type: [Number, String]
    },
    scenarioId:{
      type: [String],
      required: true,
    },
    lockGame:{
      type: Boolean,
      default: false
    },
    correction:{
      type: Boolean,
      default: false
    },
    result:{
      type: String,
      default: undefined
    },
  },
  computed: {
    currentQuestion() {
     return this.activity.question ? this.activity.question : undefined
    },
    // moreThanOneAnswer(){
    //   return this.currentQuestion.answers.length > 1;
    // }
  },
  watch: {
    activity() {
      // Reset component states
      this.introOpen = true
      this.outroOpen = false
      this.$store.dispatch('enquete/resetUserAnswers',{ actId: this.actId, scenarioId: this.scenarioId, activityId: this.activity.id})
    }
  },
  methods: {
    formUpdate(val) {
      this.modalopen = false;
      this.$store.dispatch('enquete/setUserAnswer',{ actId: this.actId, scenarioId: this.scenarioId, activityId: this.activity.id,  valuestate: val})
    },
  }
}
</script>

<style lang="scss" scoped>

</style>
