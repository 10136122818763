<template>
  <div>
  <CustomModal v-if="currentChoice" :fullscreen="true"  height="h-screen" width="w-screen" :open="!!currentChoice.media" :bg-color="`bg-midnight-blue`" :noBg="true">
    <div class="z-0 w-7/12 1480:w-8/12 text-center h-full text-yellow-light ml-auto mr-auto flex flex-col items-center justify-center">
      <div class=" font-bariol text-36 text-center mb-4 font-bold">
        {{activity.instruction}}
      </div>
      <VideoBox v-if="currentChoice" :format="currentChoice.format" :video="currentChoice.media" @action="getVideoResult($event)" :start="true"  class-container-tag=" rounded-md border-2 border-yellow-medium">
        <div v-if="currentChoice.format === 'audio'" class="flex flex-1 flex-col items-center justify-around">
          <img :src="require('../../'+currentChoice.actor)" alt="actor">
          <div>
            <div class="body-text-bold rounded-md ">{{currentChoice.value}}</div>
          </div>
          <div>
            <img src="../../assets/icons/icon-audio.svg" alt="">
          </div>
        </div>
      </VideoBox>
        <div v-if="currentChoice.format !== 'audio'"  :class="currentChoice.format === 'visio' ? `self-start place-self-start`:''" >
          <div class="flex bg-midnight-blue-light  rounded-md text-light-yellow small-body-text-bold mt-5 p-2"><img :src="require('../../assets/icons/icon-compte.svg')" class="mr-2">{{currentChoice.value}}</div>
        </div>
        <div :class="'flex w-6/12' + currentChoice.format ==='visio' ? 'justify-between' : 'flex-col justify-center items-center'">
          <div>
            <ShadowButton v-if="showButton" class="mt-5" width="auto" @click="endVideo()" theme="light-yellow-midnight-blue">{{$t.buttons.bouton_continuer}}</ShadowButton>
          </div>
        </div>
    </div>
  </CustomModal>
  <div class="flex" :class="currentTheme">
    <article class="w-11/12">
      <!-- <header class="flex justify-between w-full items-start">
        <div>
          <p class="text-left huge-text-bold mb-2 text-dark-midnight-blue">{{activity.instruction}}</p>
          <p class="text-left body-text text mb-8 text-blue-grey">{{activity.help}}</p>
        </div>
        <div>
          <ShadowButton theme="default-grey" width="50px" @click="$emit('close')">x</ShadowButton>
        </div>
      </header> -->
        <ul class="flex flex-wrap">
          <li v-for='(choice, index) in currentQuestion.choices' :key="choice" class="w-4/12 1480:w-3/12">
            <Actors :theme="actorTheme" :vertical='true' :success="false" :lockGame="lockGame" :correction="correction" :answers="currentQuestion.answers" :value="index" :text="choice.value" @formUpdate="formUpdate($event, choice)" :actorPic="choice.actor"
            :checked="answers.indexOf(index) > -1"></Actors>
          </li>
        </ul>
    </article>
  </div>
  </div>
</template>

<script>

import Actors from "../../components/Actors";
import {mapState} from "vuex";
import CustomModal from "../../components/CustomModal";
import ShadowButton from "../../components/ShadowButton";
import VideoBox from "../../components/VideoBox";


export default {
  components: {VideoBox,ShadowButton, CustomModal, Actors},
  mounted() {
    this.introOpen = true;
  },
  data() {
    return {
      introOpen: false,
      outroOpen: false,
      currentChoice:undefined,
      showButton: false,
      answers: []
    }
  },
  props: {
    activity: {
      type: Object
    },
    actorTheme:{
      type: String
    },
    actId:{
      type: [Number, String]
    },
    scenarioId:{
      type: [Number, String]
    },
    lockGame:{
      type: Boolean,
      default: false
    },
    correction:{
      type: Boolean,
      default: false
    },

  },
  computed: {
    currentQuestion() {
      return this.activity.question ? this.activity.question : undefined
    },
    ...mapState({
      currentTheme: state =>  state.currentTheme
    })
  },
  watch: {
    activity() {
      // Reset component states
      this.introOpen = true
      this.outroOpen = false
      this.currentChoice =undefined
      this.showButton = false
      this.answers = []
      this.$store.dispatch('enquete/resetUserAnswers',{ actId: this.actId, scenarioId: this.scenarioId, activityId: this.activity.id})
    }
  },
  methods: {
    formUpdate(val, choice) {
      if (this.answers.indexOf(val.value) < 0) {
        this.answers.push(val.value)
      }
      this.currentChoice = choice

      this.showButton = false
    },
    endVideo(){
      if (this.answers.length === this.currentQuestion.choices.length) {
        this.$store.dispatch('enquete/setUserAnswer',{ actId: this.actId, scenarioId: this.scenarioId, activityId: this.activity.id,  valuestate: {checked: true, reset:true, value: this.answers}})
      }
      this.currentChoice = undefined
    },
    getVideoResult(val) {
      if(val === 'end') {
        this.showButton = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
