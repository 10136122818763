<template>
  <div>
    <div class="flex w-screen justify-center">
      <CustomModal v-if="!!currentChoice" :fullscreen="true" bgopacity="bg-opacity-20" height="h-screen" width="w-screen" :open="modalopen" :bg-color="`bg-${currentTheme}-light`">
        <div class="z-0 bg-white p-5 rounded-md w-3/12 1480:w-3/12 text-center text-yellow-light ml-auto mr-auto flex flex-col items-center justify-center text-black" v-if="currentChoice">
          <img v-if="currentChoice && currentChoice.media.format === 'pic'" :src="require('../../'+currentChoice.media.path)" >
          <VideoBox v-if="currentChoice && ['audio', 'visio'].includes(currentChoice.media.format)" :format="currentChoice.media.format"   :video="currentChoice?.media.path" @action="getVideoResult($event)" :start="true"  class-video-tag=" rounded-md border-2 border-yellow-medium">
            <div  v-if="currentChoice.media.format === 'audio'" class="flex flex-1 flex-col items-center justify-around">
              <div>
                <img src="../../assets/icons/icon-audio.svg" alt="">
              </div>
            </div>
          </VideoBox>
          <img v-if="currentChoice.media.format === 'image'" :src="mediaserverurl+currentChoice.media.path" >
          <p class="huge-text-bold text-midnight-blue mt-5">{{currentChoice.title}}</p>
          <p class="body-text text-midnight-blue text blue-quest-dark mt-5">{{currentChoice.desc}}</p>
          <div v-if="currentChoice.action" class="bg-blue-quest-ultralight rounded-md text-blue-quest-dark small-body-text-bold mt-5 p-2">{{currentChoice.action}}</div>
          <ShadowButton class="mt-5" width="120px" @click="updateReadStatus(currentChoice.id)" :theme="`${currentTheme}-ultra-light-grey`">OK</ShadowButton>
        </div>
      </CustomModal>
    </div>
    <div class="flex" :class="currentTheme">
      <article class="w-11/12">
        <!-- <header class="flex justify-between w-full items-center">
          <div>
            <p class="text-left huge-text-bold mb-2 text-dark-midnight-blue">{{activity.instruction}}</p>
          <p class="text-left body-text text mb-8 text-blue-grey">{{activity.help}}</p>
          </div>
          <div>
            <ShadowButton theme="default-grey" width="50px" @click="$emit('close')">x</ShadowButton>
          </div>
        </header> -->
          <div class="choice-container">
            <img :src="mediaserverurl+activity.pics" alt="">
            <div v-for='choice of currentQuestion.choices' @click="openModal(choice)" :key="choice" :class="[this.haveBeenRead(choice.id) ? 'read-ok': '', 'choice flex justify-center items-center']" :style="`left: ${choice.coords.x}%; top:${choice.coords.y}% `">
                <img class="w-3/12" src="../../assets/icons/ico-plus-light.svg" v-if="!this.haveBeenRead(choice.id)">
                <img class="h-1/5" src="../../assets/icons/ico-check-valid-quest.svg"  v-if="this.haveBeenRead(choice.id)">
            </div>
          </div>
      </article>

    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import ShadowButton from "../../components/ShadowButton";
import CustomModal from "../../components/CustomModal"
import VideoBox from "../../components/VideoBox";
import { setScoreActivity } from '@/composables/user_log';

export default {
  components: {VideoBox, ShadowButton, CustomModal},
  mounted() {
    this.introOpen = true;
  },
  data() {
    return {
      'mediaserverurl': process.env.VUE_APP_MEDIA_URL,
      modalopen: false,
      currentChoice:undefined,
      spotsRead:[]
    }
  },
  props: {
    activity: {
      type: Object
    },
    actId:{
      type: [Number, String]
    },
    scenarioId:{
      type: [Number, String]
    },
    lockGame:{
      type: Boolean,
      default: false
    },
    correction:{
      type: Boolean,
      default: false
    }
  },
  computed: {
    currentQuestion() {
      return this.activity.question ? this.activity.question : undefined
    },
    haveReadAll(){
      return this.spotsRead.length === this.currentQuestion.choices.length
    },
    ...mapState({
      currentTheme: state =>  state.currentTheme
    })
  },
  watch: {
    activity() {
      // Reset component states
      this.introOpen = true
      this.modalopen = false
      this.currentChoice = undefined
      this.spotsRead = []
      this.$store.dispatch('enquete/resetUserAnswers',{ actId: this.actId, scenarioId: this.scenarioId, activityId: this.activity.id})
    }
  },
  methods: {
    formUpdate(val) {
      this.$store.dispatch('enquete/setUserAnswer',{ actId: this.actId, scenarioId: this.scenarioId, activityId: this.activity.id,  valuestate: val})
    },
    haveBeenRead(id){
      return this.spotsRead.includes(id);
    },
    openModal(choice) {
      this.currentChoice = choice
      this.modalopen = true;
    },
    updateReadStatus(id){
      if (this.spotsRead.indexOf(id) < 0) {
        this.spotsRead.push(id)
      }

      this.modalopen = false;
      this.currentChoice = undefined;

      if(this.haveReadAll) {
        this.formUpdate({checked: true, reset:true, value: this.spotsRead})
        setScoreActivity(this.activity, true);
      }
    },
    getVideoResult(){

      if(this.haveReadAll) {
        //  console.log('ok');
      }
    }


  }
}
</script>

<style lang="scss" scoped>
  .choice-container{
    @apply relative
  }
  .choice{
    &.read-ok{
      &:hover{
        @apply bg-md-green bg-opacity-80 border-8 border-green
      }
      &:active{
        @apply bg-md-green bg-opacity-80 border-8 border-green opacity-100
      }
      @apply bg-md-green bg-opacity-80 border-8 border-green
    }
    &:hover{
      @apply bg-yellow-medium bg-opacity-80 cursor-pointer opacity-80
    }
    &:active{
      @apply border-8 bg-white bg-opacity-50 border-yellow-medium cursor-pointer opacity-80
    }
    &:after {
      content: "";
      display: block;
      padding-bottom: 100%;
    }
    @apply w-[15%] bg-white bg-opacity-50 absolute rounded-full cursor-pointer
  }
</style>
