<template>
  <div>
    <CustomModal v-if="currentChoice" :fullscreen="true"  height="h-screen" width="w-screen" :open="!!currentChoice.media" :bg-color="`bg-midnight-blue`" :noBg="true">
      <div class="z-0 w-8/12 text-center text-yellow-light ml-auto mr-auto flex flex-col items-center justify-center h-full">
        <div class="w-full absolute top-10 left-10" >
          <ShadowButton theme="default-midnight" width="50px" @click="currentChoice = undefined">&lt;</ShadowButton>
        </div>
        <VideoBox  :lockGame="lockGame" :correction="correction" v-if="currentChoice" :format="currentChoice.format" :video="currentChoice.media" @action="getVideoResult($event)" :start="true"  :class-container-tag="`rounded-md border-2 border-yellow-medium `">
          <div v-if="currentChoice.format === 'audio'" class="flex flex-1 flex-col items-center justify-around h-3/4">
            <img :src="mediaserverurl+currentChoice.actor" alt="actor">
            <div class="pt-4">
              <div class="body-text-bold rounded-md ">{{currentChoice.title}}</div>
            </div>
            <div>
              <img src="../../assets/icons/icon-audio.svg" alt="">
            </div>
          </div>
        </VideoBox>
          <div v-if="currentChoice.format !== 'audio'"  :class="currentChoice.format === 'visio' ? `self-start place-self-start`:''" >
            <div class="flex bg-midnight-blue-light  rounded-md text-light-yellow small-body-text-bold mt-5 p-2"><img :src="require('../../assets/icons/icon-compte.svg')" class="mr-2">{{currentChoice.title}}</div>
          </div>
          <div :class="'flex w-6/12' + currentChoice.format ==='visio' ? 'justify-between' : 'flex-col justify-center items-center'">
            <div>
              <ShadowButton v-if="showButton" class="mt-5" width="auto" @click="endVideo()" theme="light-yellow-midnight-blue">{{$t.buttons.bouton_continuer}}</ShadowButton>
            </div>
          </div>
      </div>
    </CustomModal>
    <div class="flex" :class="currentTheme">
      <article class="w-11/12">
        <div>
          <ul class="flex gap-5 justify-center">
            <li v-for='choice in currentQuestion.choices' :key="choice" class="">
              <!-- <Actors format="visio" :theme="actorTheme" :actorPic="choice.actor" :vertical='true' :success="false" :lockGame="lockGame" :correction="correction" :answers="currentQuestion.answers" :value="index" :text="choice.title" @formUpdate="formUpdate($event, choice)" :checked="answers.indexOf(index) > -1"></Actors> -->
              <div :class="{ 'success': status[choice.media]}" @click="formUpdate({checked:true}, choice)" class="cursor-pointer phone">
                <div class="flex flex-col items-center justify-center h-full w-full">
                  <div class="flex flex-col items-center justify-center h-1/2">
                    <img :src="mediaserverurl+choice.actor"  class="w-[70%] h-full"/>
                    <div class="text-midnight-blue body-text-bold py-2 px-4">{{choice.title}}</div>
                  </div>
                  <div class="flex flex-col items-center">
                    <div v-if="status[choice.media]" class="justify-center items-center w-full">
                      <div class=" border-green h-16 w-16 flex justify-center items-center mx-auto rounded-full border-2 border-solid" >
                        <img :src="require('../../assets/icons/ico-check-valid-quest.svg')" class="mx-auto justify-self-end" />
                      </div>
                      <p class="text-green">{{$t.videos.etat_appel_termine}}</p>
                    </div>
                    <div v-else class="">
                      <img :src="require('../../assets/icons/icon-phone.svg')" class="mx-auto justify-self-end" />
                      <p class="text-green underline">{{$t.videos.bouton_repondre}}</p>
                    </div>
                  </div>
                </div>

              </div>
            </li>
          </ul>


        </div>
      </article>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import CustomModal from "../../components/CustomModal";
import ShadowButton from "../../components/ShadowButton";
import VideoBox from "../../components/VideoBox";


export default {
  emit: ['render'],
  components: {VideoBox,ShadowButton, CustomModal},
  mounted() {
    this.introOpen = true;
  },
  data() {
    return {
      introOpen: false,
      outroOpen: false,
      currentChoice:undefined,
      showButton: false,
      status: {},
      answers : [],
      'mediaserverurl': process.env.VUE_APP_MEDIA_URL,

    }
  },
  props: {
    activity: {
      type: Object
    },
    actorTheme:{
      type: String
    },
    actId:{
      type: [Number, String]
    },
    scenarioId:{
      type: [Number, String]
    },
    lockGame:{
      type: Boolean,
      default: false
    },
    correction:{
      type: Boolean,
      default: false
    },
    result:{
      type: String,
      default: undefined
    }

  },
  computed: {
    currentQuestion() {
      return this.activity.question ? this.activity.question : undefined
    },
    ...mapState({
      currentTheme: state =>  state.currentTheme
    })
  },
  watch: {
    activity() {
      // Reset component states
      this.introOpen = true
      this.outroOpen = false
      this.currentChoice = undefined
      this.showButton = false
      this.status = {}
      this.answers = []
      this.$store.dispatch('enquete/resetUserAnswers',{ actId: this.actId, scenarioId: this.scenarioId, activityId: this.activity.id})
    }
  },
  methods: {
    formUpdate(val, choice) {
      this.currentChoice = choice
      if(this.answers.indexOf(val.value) < 0){
        this.answers.push(val.value)
      }

      if(this.answers.length === this.currentQuestion.choices.length){
        this.$store.dispatch('enquete/setUserAnswer',{ actId: this.actId, scenarioId: this.scenarioId, activityId: this.activity.id,  valuestate: {checked: true, reset:true, value: this.answers}})
      }

      this.status[choice.media] = true
      this.showButton = false
    },
    resetAnswers() {
      this.$store.dispatch('enquete/resetUserAnswers',{ actId: this.actId, scenarioId: this.scenarioId, activityId: this.activity.id})
    },
    endVideo(){
      this.currentChoice = undefined
    },
    getVideoResult(val) {
      if(val === 'end') {
        this.showButton = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.phone {
  &:before  {
    content:'';
    display: flex;
    width:100px;
    position:absolute;
    transform: translateY(-200px) translateX(65px);
    height: 10px;
    border-radius:10px;
    @apply bg-light-grey
  }
  border:10px solid;
  border-radius:40px;
  &.error{
    @apply border-light-red bg-light-red w-[240px] h-[440px] flex items-center
  }
  &.success{
    &:before{
      @apply bg-green
    }
    @apply border-green bg-light-green w-[240px] h-[440px] flex items-center
  }
  @apply border-light-grey  w-[240px] h-[440px] flex items-center

}
</style>
