<template>
  <div class="flex" v-if="currentQuestion">
    <article class="w-11/12">
      <div>
        <div v-for="choice of currentQuestion.choices" :key="choice" class="text-left">
          <p>{{choice.value}}</p>
          <div class="flex gap-5 mt-5 mb-3">
            <shadow-button width="210px" :disabled="lockGame" :theme="`default-grey-yesno ${this.statusAnswers['vrai']}-yesno`" :status="answers[choice.id] !== undefined && answers[choice.id] === (!choice.answer ? -1 : choice.id ) && !correction && !result ? 'clicked' : '' " @click="setAnswer(choice.id, !!choice.answer, true)"><span class="body-text-bold">Vrai</span></shadow-button>
            <shadow-button width="210px" :disabled="lockGame" :theme="`default-grey-yesno ${this.statusAnswers['faux']}-yesno`" :status="answers[choice.id] !== undefined && answers[choice.id] === (!!choice.answer ? -1 : choice.id ) && !correction && !result ? 'clicked' : ''" @click="setAnswer(choice.id, !choice.answer, false)"><span class="body-text-bold">Faux</span></shadow-button>
          </div>
        </div>
      </div>
    </article>

  </div>
</template>

<script>

import ShadowButton from "../../components/ShadowButton";
import {mapState} from 'vuex'
export default {
  components: {ShadowButton},
  mounted() {
    this.introOpen = true;
    this.currentQuestion.choices.forEach( c =>
        this.answers[c.id] = undefined
    )
    this.statusAnswers = {}
  },
  data() {
    return {
      answers: {},
      statusAnswers: {},
      currentAnswerClicked: undefined
    }
  },
  props: {
    activity: {
      type: Object
    },
    actId:{
      type: [Number, String]
    },
    scenarioId:{
      type: [Number, String]
    },
    lockGame:{
      type: Boolean,
      default: false
    },
    correction:{
      type: Boolean,
      default: false
    },
    result:{
      type: String,
      default: undefined
    },
  },
  computed: {
    currentQuestion() {
     return this.activity.question ? this.activity.question : undefined
    },
    ...mapState({
      currentTheme: state => state.currentTheme,
    }),
  },
  watch: {
    activity() {
      // Reset component states
      this.answers = {}
      this.statusAnswers = {}
      this.currentAnswerClicked = undefined

      this.introOpen = true;
      
      if (this.currentQuestion) {
        this.currentQuestion.choices.forEach( c => this.answers[c.id] = undefined)
      }
      this.$store.dispatch('enquete/resetUserAnswers',{ actId: this.actId, scenarioId: this.scenarioId, activityId: this.activity.id})
    },
    result(result){
      if (result){
        if (this.currentQuestion.choices[0].answer){
          if (this.currentAnswerClicked){
            this.statusAnswers['vrai'] = 'success'
          } else {
            this.statusAnswers['faux'] = 'error'
          }
        } else {
          if (this.currentAnswerClicked){
            this.statusAnswers['vrai'] = 'error'
          } else {
            this.statusAnswers['faux'] = 'success'
          }
        }
      }
    },
    correction() {
      const key = Object.keys(this.statusAnswers)[0]
      const otherKey = (key == 'vrai' ? 'faux' : 'vrai')
      
      if (this.statusAnswers[key] == 'error') {
        this.statusAnswers[otherKey] = 'success'
      }
    }
  },
  methods: {
    formUpdate(val) {
      this.$store.dispatch('enquete/setUserAnswer',{ actId: this.actId, scenarioId: this.scenarioId, activityId: this.activity.id,  valuestate: val})
    },
    setAnswer(id, val, status){
      this.answers[id] = val ? id : -1
      if(!Object.values(this.answers).includes(undefined)){
        this.formUpdate({checked: true, reset:true, value: Object.values(this.answers)})
      }
      this.currentAnswerClicked = status;
    }
  },

}
</script>

<style lang="scss" scoped>

</style>
