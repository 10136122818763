<template>
  <div>
  <CustomModal v-if="media" :fullscreen="true" height="h-screen" width="w-screen" :class="[gameStatus ? gameStatus : '', 'overflow-hidden']" :open="!!media" :bg-color="'bg-midnight-blue'" :noBg="true">
    <div class="z-0 w-10/12 text-center text-yellow-light ml-auto mr-auto flex flex-col items-center justify-center">
        <div class="w-full absolute top-10 left-10" >
          <ShadowButton theme="default-midnight" width="50px" @click="$emit('render')">&lt;</ShadowButton>
        </div>
      <div class="flex justify-center items-center gap-10 transition-all duration-75">
        <div class="w-7/12 1480:w-8/12" :class="['z-1 flex items-center justify-center flex-col transition-all duration-75']" style="height: 80vh">
          <div v-if="(textAnswer) && (this.currentChoice?.media || gameStatus === 'success')" :class="`flex mb-6 justify-center flex-col align-items ${answerCorrect(gameStatus) ? noAnwserMode ? 'default' : 'md-green' : 'md-red'}`">
            <p class="body-text" v-if="!noAnwserMode">{{ answerCorrect(gameStatus) ? 'Bonne réponse' : 'Mauvaise réponse' }}</p>
            <p class="huge-text-bold">{{ textAnswer }}</p>
          </div>
          <ShadowButton v-if="false" class="mt-5" @click="closeModal()" :theme="buttonTheme" width="200px">zefezf{{$t.buttons.bouton_continuer}}</ShadowButton>
          <VideoBox ref="video" :askForRetry="askForRetry" :key="media && media.video" :format="videoFormat" :status="gameStatus" @elapsed="setProgressBar($event)" :restart="restart" @duration="setDuration($event)" v-if="media && media?.video" :video="media.video" @action="getVideoResult($event)" :start="true"  class-video-tag=" rounded-md"></VideoBox>
          <div v-if="!gameStatus" :class="[videoFormat === 'visio' ? 'self-start' : '', 'flex bg-midnight-blue-light whitespace-pre rounded-md text-light-yellow small-body-text-bold mt-5 p-2']"><img :src="require('../../assets/icons/icon-compte.svg')" class="pr-2">{{ media.actor }}</div>
          <div v-if="gameStatus && !answerCorrect(gameStatus)" class="self-start flex bg-midnight-blue-light whitespace-pre rounded-md text-md-red small-body-text-bold mt-5 p-2"><img :src="require('../../assets/icons/icon-compte-light-red.svg')" class="pr-2">{{ media.actor }}</div>
          <div v-if="gameStatus && answerCorrect(gameStatus)" class="self-start flex bg-midnight-blue-light whitespace-pre rounded-md text-md-green small-body-text-bold mt-5 p-2"><img :src="require('../../assets/icons/icon-compte-light-green.svg')" class="pr-2">{{ media.actor }}</div>
        </div>
          <div class="button-panel z-0" :class="{ 'w-5/12': media && media.video }" v-if="!hideOptions || (showCloseModalButton || showNextGameButton || showRetryButton)">
            <div :class="[isVideoPlaying ? 'text-dark-grey': 'text-yellow-light', 'huge-text-bold text-left']" v-if="!gameStatus && !hideOptions">{{ currentItem.label }}</div>
            <div class="huge-text-bold text-left text-md-red ml-4 flex items-center gap-5" v-if="gameStatus === 'error' && !hideOptions" >
              <img class="h-5" :src="require('../../assets/icons/icon-close.svg')" v-if="false">
              Oops, c'est une mauvaise réponse!
            </div>

            <div class="text-left mt-4 1480:mt-10" v-if="noAnwserMode">
              <template v-if="!hideOptions">
                <template v-for="choice of currentItem.choices" :key="choice">
                  <ShadowButton :id="`btn-${choice.id}`" v-if="isNextChoice(choice.id) && !isVideoPlaying" :key="choice" :class="[!gameStatus || currentChoice.id === choice.id ? 'opacity-100' : 'opacity-50 border-0', '1480:mt-2 no-border pr-3 py-1 1480:py-3 text-left body-text-bold btn-to-answer']" @click="!gameStatus ? answer(choice) : ''" :theme="buttonTheme"><span :class="'body-text-bold border-none'">{{choice.text}}</span></ShadowButton>
                  <div v-else>
                    <span @click="this.answersDone.includes(choice.id) && !isVideoPlaying ? startVideoWithId(choice.id) : ''" :class="[this.answersDone.includes(choice.id) ? ' text-yellow-light cursor-pointer' :'text-grey-medium', 'p-5 mb-4 bg-midnight-blue-light rounded-md flex body-text-bold']">
                       <img v-if="this.answersDone.includes(choice.id)" :src="require('../../assets/icons/ico-check.svg')"  alt="done">
                       <img v-else :src="require('../../assets/icons/icon-lock.svg')"  alt="locked">
                      <div class="ml-3">{{choice.text}}</div>
                    </span>
                    <ShadowButton v-if="showNextGameButton" class="mt-5" @click="nextQuestion" :theme="buttonTheme" width="200px">
                      {{ $t.videos.bouton_continuer}}  </ShadowButton>
                  </div>
                </template>
              </template>
                <ShadowButton v-if="showCloseModalButton" class="mt-5" @click="closeModal()" :theme="buttonTheme" width="200px">{{$t.videos.bouton_continuer}} </ShadowButton>
                <ShadowButton v-if="showRetryButton" class="mt-5" @click="ry" :theme="buttonTheme" width="200px">{{$t.videos.bouton_reesayer}}</ShadowButton>
            </div>

            <div class="text-left mt-4 1480:mt-10" v-else>
              <template v-if="!hideOptions">
                <ShadowButton v-for="choice of currentItem.choices" :disabled="!answerCorrect(gameStatus) || isVideoPlaying || showCloseModalButton" :key="choice"
                  class="1480:mt-2 border-none pr-3 py-1 1480:py-3 text-left body-text-bold"
                  :class="[gameStatus  && (currentChoice && currentChoice.id === choice.id ? 'opacity-100' : '')]"
                  @click="(currentChoice && currentChoice.id === choice.id) || isVideoPlaying || showCloseModalButton ? '' : answer(choice)"
                  :theme="[gameStatus && (currentChoice && currentChoice.id === choice.id) ? buttonTheme + ' selection' :  buttonTheme]">
                  <span :class="['body-text-bold border-none flex items-center', gameStatus && gameStatus === 'error' && (currentChoice && currentChoice.id === choice.id ? 'text-red text-bold no-underline' : 'text-md-red')]">
                    <img class="mr-2" src="@/assets/icons/icon-close.svg" v-if="gameStatus && gameStatus === 'error' && (currentChoice && currentChoice.id === choice.id)">
                     <img class="mr-2" src="@/assets/icons/ico-check-valid-quest.svg" v-if="gameStatus && gameStatus === 'success' && (currentChoice && currentChoice.id === choice.id)">
                    {{choice.text}}</span>
              </ShadowButton>
              </template>
              <ShadowButton v-if="showRetryButton" class="mt-5 pl-1" @click="retry" :theme="buttonTheme === 'error-dark-red-light-red' ? 'error-red-light-red' : 'success-reverse'" width="200px">{{$t.videos.bouton_reesayer}}</ShadowButton>
              <ShadowButton v-if="showCloseModalButton" class="mt-5 pl-1" @click="closeModal()" theme="success-light-green " width="200px">{{$t.videos.bouton_continuer}} </ShadowButton>
              <ShadowButton v-if="showNextGameButton" class="mt-5 pl-1" @click="nextQuestion" theme="success" width="200px"></ShadowButton>
            </div>

          </div>

      </div>
      <div class="flex flex-col justify-between  w-8/12 pt-10">
        <article :class="[progress > 99 ? 'full':'',
                        !gameStatus ? 'default' : !answerCorrect(gameStatus) ? 'error-status' : 'success-status' ,
                         `flex rounded-md items-end pb-bg body-text-bold  w-full`]">
          <div class="w-full text-left">
            <div class=" w-full full h-3 rounded-md ">
              <div class="pb-content h-3  rounded-md" :style="{width: progress + '%'}"></div>
            </div>
          </div>
        </article>
      </div>
    </div>
  </CustomModal>
  <div class="flex" :class="currentTheme">
    <article class="w-11/12">
      <!-- <header class="flex justify-between w-full items-center">
        <div>
          <p class="text-left huge-text-bold mb-2 text-dark-midnight-blue">{{activity.instruction}}</p>
          <p class="text-left body-text text mb-8 text-blue-grey">{{activity.help}}</p>
        </div>
        <div>
          <ShadowButton theme="default-grey" width="50px" @click="$emit('close')">x</ShadowButton>
        </div>
      </header> -->
        <ul class="flex flex-wrap">
          <li class="w-3/12">
            <Actors :lockGame="lockAnswer" :key="activity.id" :actor-pic="activity.actor.media" :vertical='true' :success="true" :text="activity.actor.name" @formUpdate="startVideo()"></Actors>
          </li>
        </ul>
    </article>
  </div>

  </div>
</template>

<script>

import Actors from "../../components/Actors";
import {mapState} from "vuex";
import CustomModal from "../../components/CustomModal";
import ShadowButton from "../../components/ShadowButton";
import VideoBox from "../../components/VideoBox";
import {GameStatus} from "../../constants/game-config";


export default {
  components: {VideoBox,ShadowButton, CustomModal, Actors},
  mounted() {
    this.introOpen = true;
    this.progress = 0
    this.noAnwserMode = !this.activity.show_correction;
    if(this.noAnwserMode){
    this.numberOfSteps = this.activity.questions.reduce((val, quest) => {
      return val + quest.choices.length
    },0)
    }else{
      this.numberOfSteps = this.activity.questions.length
    }
    console.log(this.numberOfSteps)

  },
  emits: ['render'],
  data() {
    return {
      showNextGameButton:false,
      showRetryButton: false,
      showCloseModalButton: false,
      introOpen: false,
      outroOpen: false,
      currentChoice:undefined,
      itemIndex: undefined,
      buttonActivated: false,
      showButton: false,
      answers : [1],
      playbackTime:0,
      mediaDuration:0,
      gameStatus:undefined,
      hideOptions: false,
      restart:false,
      textAnswer: undefined,
      media:undefined,
      answerMode:false,
      previousMedia:undefined,
      progress:0,
      askForRetry: false,
      isVideoPlaying: false,
      closeButton: false,
      displayAnswer:false,
      answersDone:[],
      numberOfSteps: 1,
      lockAnswer: false

    }
  },
  props: {
    activity: {
      type: Object
    },
    actorTheme:{
      type: String
    },
    actId:{
      type: [Number, String]
    },
    scenarioId:{
      type: [Number, String]
    },
    lockGame:{
      type: Boolean,
      default: false
    },
    correction:{
      type: Boolean,
      default: false
    },
    result:{
      type: String,
      default: undefined
    }


  },

  computed: {
    currentItem() {
      return this.activity.questions? this.activity.questions[this.itemIndex] : undefined
    },
    ...mapState({
      currentTheme: state =>  state.currentTheme
    }),
    buttonTheme(){
      if(!this.gameStatus){
        return 'light-yellow-midnight-blue'
      }
      return this.gameStatus === GameStatus.SUCCESS ? 'success-dark-green-light-green' : 'error-dark-red-light-red';
    },
    videoFormat() {
      if (!this.currentItem)
        return null

      return (this.currentItem.type.slug == 'conversation_facetime' ? 'phone' : null)
    }
  },
  watch: {
    activity(activity) {
      // Reset component states
      this.introOpen = false;
      this.showNextGameButton = false
      this.showRetryButton = false
      this.showCloseModalButton = false
      this.outroOpen = false
      this.currentChoice = undefined
      this.itemIndex = undefined
      this.buttonActivated = false
      this.showButton = false
      this.answers  = [1]
      this.playbackTime = 0
      this.mediaDuration = 0
      this.gameStatus = undefined
      this.hideOptions = false
      this.restart = false
      this.textAnswer = undefined
      this.media = undefined
      this.answerMode = false
      this.previousMedia = undefined
      this.progress = 0
      this.closeButton = false
      this.displayAnswer = false
      this.answersDone = []
      this.numberOfSteps = 1
      this.lockAnswer = false

      this.$store.dispatch('enquete/resetUserAnswers',{ actId: this.actId, scenarioId: this.scenarioId, activityId: this.activity.id})

      if (activity) {
        this.introOpen = true;
        this.noAnwserMode = !activity.show_correction;
        
        if(this.noAnwserMode){
          this.numberOfSteps = activity.questions.reduce((val, quest) => {
            return val + quest.choices.length
          },0)
        }else{
          this.numberOfSteps = activity.questions.length
        }
      }
    }
  },
  methods: {
    startVideo(){
      this.itemIndex = 0;
      this.displayAnswer = false;
      this.previousMedia = this.media = {
        video: this.activity.introMedia && this.activity.introMedia.path,
        actor: this.activity.actor.name
      }

      if (!this.activity.introMedia) {
        this.getVideoResult('end')
      }
    },
    startVideoWithId(choiceId){

      const choice = this.currentItem.choices.find( v => v.id === choiceId)
      this.previousMedia = this.media
      this.textAnswer = choice.text

      this.media = {
        video: choice.media.path,
        actor: this.activity.actor.name
      }
      if (!this.activity.introMedia) {
        this.getVideoResult('end')
      }
    },
    isNextChoice(id) {
      if(this.currentChoice)
        console.log(this.hasNextChoice(id) ,this.getChoiceIndex(id), this.currentItem.choices.indexOf(this.currentChoice)+1)
      return  !this.currentChoice && this.currentItem.choices[0].id === id
          ? true
          : this.currentChoice && this.getChoiceIndex(id) === this.currentItem.choices.indexOf(this.currentChoice) +1
    },
    hasNextChoice(id) {
      return this.getChoiceIndex(id) + 1 < this.currentItem.choices.length
    },
    getChoiceIndex(id){
      return this.currentItem.choices.indexOf(this.currentItem.choices.find( c => c.id === id))
    },
    answer(choice){
      this.askForRetry = false;
      this.isVideoPlaying = true;
      if(!this.noAnwserMode){

        this.displayAnswer = false
        this.gameStatus = choice.is_correct ? GameStatus.SUCCESS :  GameStatus.ERROR
        this.textAnswer = choice.text
        this.currentChoice = choice
        if(this.gameStatus === GameStatus.SUCCESS){
          this.increaseProgressBar()
          this.hideOptions = false;
          this.showRetryButton = false
          if(!this.currentChoice.media && this.progress >99) {
            this.showCloseModalButton = true;
          }
          }else{
          this.showButton = false
          this.hideOptions = false;
          this.answerMode = true;

          if(this.currentChoice.media){
            this.showNextGameButton = false;
            this.showRetryButton = false
          }else{
            this.hideOptions = false
            this.showRetryButton = true;
          }
          //this.closeButton = true
        }

        this.previousMedia = this.media
        if(this.currentChoice.media) {
          this.media = {
            video: this.currentChoice.media.path,
            actor: this.activity.actor.name
          }
        }else{
          //this.gameStatus = 'default'
          /*if(this.itemIndex+1 ===  this.activity.questions.length){
            if(this.gameStatus === GameStatus.SUCCESS){
              this.closeButton=true
            }
          }*/
          this.showButton = true
        }
      } else {
        this.currentChoice = choice
        this.textAnswer = choice.text
        this.increaseProgressBar()
        this.answersDone.push(choice.id)
        this.media = {
          video: this.currentChoice.media.path,
          actor: this.activity.actor.name
        }
      }
    },

    getBgColor(status){
      return status === GameStatus.SUCCESS ? 'bg-light-green' : 'bg-light-red';
    },
    answerCorrect(status) {
      return (!status || status === GameStatus.SUCCESS);
    },
    setDuration(val){
      this.mediaDuration = val
    },
    setProgressBar(val){
      this.playbackTime = val
    },
    nextQuestion(){

      this.reset()
      this.itemIndex++;
    },

    reset(){
      this.textAnswer = undefined
      this.showButton = true
      this.displayAnswer = true;
      this.hideOptions = false;
      this.currentChoice = undefined
      this.gameStatus = undefined
      this.showNextGameButton = false;
      this.showRetryButton = false;
      this.showCloseModalButton = false;
      this.lockAnswer = false;
      this.askForRetry = false;
    },
    retry(){
      this.reset()
      if(!this.noAnwserMode){
        this.media = this.previousMedia
        this.askForRetry = true;
      }
      this.isVideoPlaying = false;
    },
    closeModal(){
      this.media = undefined
      this.$store.dispatch('enquete/setUserAnswer',{ actId: this.actId, scenarioId: this.scenarioId, activityId: this.activity.id,  valuestate: {checked: true, reset:true, value: this.answers}})
      //this.itemIndex = undefined
      this.displayAnswer = false;
      this.lockAnswer = true;
    },
    cancelClick(){
      this.reset();
      this.media = undefined
      this.progress = 0
      this.itemIndex = undefined
      this.displayAnswer = false
      this.previousMedia = undefined
      this.progress = 1

    },
    increaseProgressBar(){
        this.progress += (1 / this.numberOfSteps) * 100
    },

    getVideoResult(val) {
        if (val === 'end') {
          this.isVideoPlaying = false;
          if (!this.noAnwserMode) {
            //this.askForRetry = false
            if (this.currentChoice) {
              if (!this.displayAnswer) {
                this.showNextGameButton = true;
                if (this.gameStatus === GameStatus.SUCCESS) {
                  if (this.itemIndex+1 === this.activity.questions.length) {
                    this.showCloseModalButton = true
                    this.showNextGameButton = false
                    this.hideOptions = false
                    return
                  }
                  this.itemIndex++
                  this.hideOptions = false
                  this.showNextGameButton = false;
                  //this.currentChoice = undefined
                } else {
                  if (this.itemIndex+1 === this.activity.questions.length) {
                    this.showRetryButton = true
                  }
                  this.showButton = true
                  this.closeButton = true
                  this.showRetryButton=true
                  this.hideOptions = false
                  this.showNextGameButton = false;
                  this.showCloseModalButton = false;
                  //this.currentChoice = undefined
                  // this.media = this.previousMedia
                  //this.askForRetry = true;
                  return
                }
              }
              if (this.itemIndex === this.activity.questions.length) {
               //  this.closeModal()
              }
              this.textAnswer = undefined
              this.showButton = true
              this.displayAnswer = true;
              this.currentChoice = undefined

              this.gameStatus = undefined
            } else {
              this.showButton = true
              this.hideOptions = false
              this.gameStatus = undefined
              this.displayAnswer = true;
              this.textAnswer = undefined
            }
          } else {
              this.hideOptions = false
            if (this.itemIndex+1 === this.activity.questions.length && this.currentItem.choices.length === this.answersDone.length) {
                this.showCloseModalButton = true
                this.buttonActivated=true
                return
              }
              if(this.currentItem.choices.length === this.answersDone.length ){
                this.itemIndex++;
                //this.displayAnswer = false;
                this.answersDone = [];
               //this.currentChoice = undefined;
               // this.reset()
            }
        }
        }
        if (val === 'replay') {
          this.isVideoPlaying = true;
          this.showButton = false
          this.restart = false
          this.gameStatus = undefined
        }
        if (val === 'start') {
          /* if(this.currentChoice)
          this.hideOptions = this.gameStatus === GameStatus.SUCCESS*/
          this.isVideoPlaying = true
          if(this.noAnwserMode){
            this.hideOptions = false
          }
          this.restart = false
          this.playbackTime = 0
        }

    }
  }
}
</script>

<style lang="scss" scoped>

.shadow-button {
  &:deep(.error-light-red) {
    .text {
      @apply bg-md-red border-md-red;
    }
  }

  &.border-red:deep(.text) {
    @apply border-red line-through cursor-default;
  }
}
.default{
  &.full{
    .pb-bg{
      @apply  bg-yellow-light
    }
    .pb-content{
      @apply  bg-yellow-light
    }
    @apply bg-yellow-light

  }
  &.pb-bg{
    @apply  bg-dark-midnight-blue
  }
  .pb-content{
    @apply bg-yellow-light
  }
  @apply border-0
}
.error-status{
  @extend .default;
  &.full{
    .pb-bg{
      @apply bg-md-red
    }
    .pb-content{
      @apply bg-red
    }
    @apply bg-red

  }
  &.pb-bg{
    @apply  bg-md-red
  }
  .pb-content{
    @apply bg-md-red
  }
  @apply border-0
}
.success-status{
  @extend .default;
  &.full{
    .pb-bg{
      @apply bg-green
    }
    .pb-content{
      @apply bg-green
    }
    @apply bg-green

  }
  &.pb-bg{
    @apply  bg-md-green
  }
  .pb-content{
    @apply bg-green
  }
  @apply border-0
}
  .button-panel{
    transition: all .4s;


  }
  .btn-to-answer span{
    border-width:0 !important;
    border:none;
    border-color:transparent;
  }
.fade-enter-active,
.fade-leave-active{
  transition: all .4s;
}

.fade-enter-to {
  opacity: 100;
  transform: translatex(0);

}
.fade-enter-from {
  opacity: 0;
  transform: translatex(-500px);
}

</style>
