<template>
  <main class="congrats w-screen h-screen flex flex-col items-center justify-center">
    <h1 class="font-coustard-black text-yellow-light text-72 mb-12">{{ $t.congrats.title }}</h1>
    <h2 class="font-normal text-yellow-light text-24 mb-32">{{ $t.congrats.subtitle }}</h2>

    <ShadowButton
      v-if="hasFinishedAllCourse"
      class="mb-4"
      theme="yellow-light"
      icon="download-alt"
      :disabled="isLoadingCertificate"
      @click="onDownloadCertificateClick"
    >
      {{ (isLoadingCertificate ? $t.buttons.loading : $t.buttons.bouton_certificate) }}
    </ShadowButton>

    <ShadowButton
      theme="midnight-blue-light-yellow"
      :disabled="isLoadingCertificate"
      @click="onGoHomeClick"
    >
      {{ $t.buttons.bouton_retour_accueil }}
    </ShadowButton>
  </main>
</template>

<script>
  import ShadowButton from "../components/ShadowButton";
  import { getFinishedAllCourseDate, getState } from '@/composables/user_log';
  import { downloadCertificateForUser } from '@/utils/pdfCertificate';

  export default {
    components: {
      ShadowButton
    },
    data() {
      return {
        hasFinishedAllCourse: !!getFinishedAllCourseDate(),
        isLoadingCertificate: false,
      }
    },
    methods: {
      async onDownloadCertificateClick() {
        if (this.isLoadingCertificate) {
          return
        }
        
        this.isLoadingCertificate = true

        // Get user data
        const user = getState.value?.user

        if (user) {
          await downloadCertificateForUser(user, getFinishedAllCourseDate(), this.$t.certificate)
        }

        this.isLoadingCertificate = false
      },
      onGoHomeClick() {
        this.$router.push({name: 'enquete/EnqueteWrapper', params: {noIntro: 'select'}})
      }
    },
  }
</script>

<style>
  .congrats {
    background-color: #121D2F;
    background-image: url('~@/assets/pics/congrats_background.png');
    background-repeat: no-repeat;
    background-position: center;
  }
</style>