<template>
  <div class="flex flex-col">
    <h1 class="mb-2 text-36 font-coustard-black">{{$t.login.title}}</h1>
    <div class="identitfiants mb-4">
      <span @click="showTooltip = !showTooltip" class="text-sm cursor-pointer hover:underline">{{$t.login.identifiants_link}}</span>
      <!-- <div class="tooltip py-4 px-1" v-if="showTooltip">
        <span class="pointer"></span>
        <span class="text-xs"></span>
      </div> -->
      <div v-bind:class="{'hidden': !showTooltip, 'block': showTooltip}" class="bg-midnight-blue border-0 ml-3 block z-50 font-normal leading-normal text-sm max-w-xs text-left no-underline break-words rounded-lg">
        <div>
          <div class="text-md-grey text-center p-3 text-xs">
            Si vous êtes inscrit à notre formation, un lien d'invitation vous a été transmis par mail, sinon envoyez un mail à {{$t.common.support_email}}
          </div>
        </div>
      </div>
    </div>
    <form class="flex flex-col" @submit.prevent="signIn">
      <p class="error" v-if="errorMsg">{{errorMsg}}</p>
      <input :class="['mail p-2 mb-2 border-solid border-2  rounded-md bg-grey-ultralight h-[50px]', errorMsg ? 'border-red text-red' :'border-grey-light' ]" :placeholder="$t.placeholders.email" type="text" v-model="email">
      <input :class="['lock p-2  border-solid border-2  rounded-md bg-grey-ultralight h-[50px]', errorMsg ? 'border-red text-red' :'border-grey-light' ]" :placeholder="$t.placeholders.password" :type="showPassword ? 'text': 'password'" v-model="password">
      <a class="text-xs mb-2 cursor-pointer text-right" @click="showPassword = !showPassword">{{showPassword ? 'Cacher' : 'Afficher'}} le mot de passe</a>
      <span @click="$emit('change-component', 'password-forgot')" class="text-sm mb-8 cursor-pointer hover:underline text-left">{{$t.login.password_oublier}}</span>
      <div v-if="loading" class="flex justify-center py-2">
        <svg class="animate-spin  h-5 w-5 text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
          <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
      </div>
      <ShadowButton v-else isSubmit theme="default-blue-reverse" :disabled="!email || !password">{{$t.buttons.login}}</ShadowButton>
    </form>
  </div>
</template>


<script>
import {ref} from 'vue'
import { useAuth } from "@/composables/auth";
import { useRouter } from 'vue-router'
import { mapState } from 'vuex'
import { IsFirstLevelFinished } from '@/composables/user_log';

  export default {

    name: 'Login',
    emits: 'done', // <--- don't forget to declare custom events emitted
    setup(_, { emit }) {
      const showTooltip = ref(false)
      const router = useRouter()
      let {email, password, login } = useAuth()
      const loading = ref(false);
      let errorMsg = ref('')
      const signIn = async () => {
        loading.value = true;
        let hasLogged = await login()
        loading.value = false;
        if(!hasLogged) errorMsg.value = 'Email ou mot de passe incorrect'
        else {
          emit('done')
          setTimeout(() => {
          if(IsFirstLevelFinished()){
            router.push({ name: 'enquete/EnqueteWrapper' })
                }else{
            router.push({ name: 'Home' })
            }
          }, 900)
        }
      }

      return {email, password, signIn, errorMsg, showTooltip, loading}
    },
    mounted() {
      this.$store.dispatch('gamestatus/resetAll')
    },
    computed: {
      ...mapState({
        courses: state => state.course.courses,
        actsFinished: state => state.gamestatus.actsFinished,
        missionFinished: state => state.gamestatus.missionFinished,


      })
    },
    data () {
      return {
        'showPassword': false
      }
    }
  }
</script>

<style lang="scss">
  .identitfiants {
    position: relative;
    .tooltip {
    position: absolute;
    top: 110%;
    width: 250px;
    background: grey;
    left: 50%;
    transform: translate(-50%);
    }
  }
  .mail{
    background-image: url("~@/assets/icons/ico-mail.svg");
    background-position: 10px center;
    padding-left:40px;
    background-repeat: no-repeat;
  }
  .lock {
    background-image: url("~@/assets/icons/ico-lock.svg");
    background-position: 10px center;
    padding-left:40px;
    background-repeat: no-repeat;
  }
</style>
