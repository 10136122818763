<template>
  <HeaderCoproxi></HeaderCoproxi>
  <section class="pl-20 pr-20 pb-20 text-left bg-white">
    <header>
      <ShadowButton class="pt-4 pt-10 z-0" theme="default-grey" width="50px" @click="navigateToPreviousPage"> <img src="@/assets/icons/icon-arrow-left.svg" > </ShadowButton>
      <h2 class="font-coustard-black z-0 text-bold text-6xl text-midnight-blue">{{$t.documentation.titre_documentation}}</h2>
      <p class="text-blue-grey font-normal">{{$t.documentation.texte_documentation}}</p>
    </header>
    <div class="btn-group mt-14 flex gap-5 ">
      <ShadowButton @click="scrollToAnchorPoint('-mission')" theme="default-blue" icon="down-arrow-midnight" :disabled="false">{{$t.buttons.bouton_scolling_missions}}</ShadowButton>
      <ShadowButton @click="scrollToAnchorPoint('-quest')" theme="default-blue" icon="down-arrow-midnight" :disabled="false">{{$t.buttons.bouton_scolling_enquetes}}</ShadowButton>

      <ShadowButton
        v-if="hasFinishedAllCourse"
        theme="default-blue-reverse"
        icon="download"
        :disabled="isLoadingCertificate"
        @click="onDownloadCertificateClick"
      >
        {{ (isLoadingCertificate ? $t.buttons.loading : $t.buttons.bouton_certificate) }}
      </ShadowButton>
    </div>
    <section  class="mt-28" ref="mission">
      <header class="flex">
        <h3 class="text-blue-grey text-4xl">Les missions (niveau 1)</h3>
        <div class="text-blue-grey flex-grow border-t-2 h-2 mt-5 ml-10 mr-10"></div>
      </header>
      <div v-if="missionFinished.length===0" class="p-10 body-text"> {{$t.documentation.empty_mission}}</div>
      <div v-for='m of missions' :key="m" :ref="m.id.substring(0,3)" :id="m.id.substring(0,3)">
      <article   v-if="missionFinished.includes(m.id) && m.media.length !== 0">
        <header class="text-midnight-blue grand-text-bold mt-10 mb-5">{{m.title}}</header>
        <div class="flex flex-wrap gap-4 justify-left">
          <div v-if="m.media.length === 0">{{$t.documentation.empty_section}}</div>
          <shadow-button @click="dlPdf(me.path)" class="text-midnight-blue button" max-width="240px" width="240px"  v-for='me of m.media' :key="me">
            <img class="m-auto" :src="me.path.toLowerCase().includes('pdf') ? require('@/assets/icons/icon-pdf.png') : require('@/assets/icons/ico-play-video.svg')" alt="icon media">
            <p  class="text-xl body-text-bold mt-2 text-blue-midnight  leading-5">{{ me.title }}</p>
            <small class="text-blue-grey body-text" >{{me.path.toLowerCase().includes('pdf') ? $t.buttons.bouton_telecharger_PDF : $t.buttons.bouton_play_video}}</small>
          </shadow-button>
          </div>
      </article>
      </div>
    </section>
    <section class="mt-28 " ref="quest">
      <header class="flex">
        <h3 class="text-blue-grey text-4xl">Les enquêtes (niveau 2)</h3>
        <div class="text-blue-grey flex-grow border-t-2 h-2 mt-5 ml-10 mr-10"></div>
      </header>
      <div v-if="questFinished.length===0" class="p-10 body-text">{{$t.documentation.empty_quest}}</div>
      <div v-for='q of quests' :key="q" :ref="q.id.substring(0,3)" :id="q.id.substring(0,3)">
      <article v-if="questFinished.includes(q.id) && q.media.length !== 0">
        <header class="text-midnight-blue grand-text-bold mt-10 mb-5">{{q.title}}</header>
        <div class="flex flex-wrap gap-4 justify-left">
          <shadow-button @click="dlPdf(med?.path)" class="text-midnight-blue button"  max-width="240px" width="240px"  v-for='med of q.media' :key="med" >
            <img class="m-auto" :src="med?.path?.toLowerCase().includes('pdf') ? require('@/assets/icons/icon-pdf.png') : require('@/assets/icons/ico-play-video.svg')" alt="icon media">
            <p  class="text-xl body-text-bold mt-2 text-blue-midnight  leading-5">{{ med.title }}</p>
            <small class="text-blue-grey body-text" >{{med?.path?.toLowerCase().includes('pdf') ? $t.buttons.bouton_telecharger_PDF : $t.buttons.bouton_play_video}}</small>
          </shadow-button>
        </div>
      </article>
      </div>
    </section>
  </section>
</template>

<script>
  import ShadowButton from "../components/ShadowButton";
  import HeaderCoproxi from "../components/HeaderCoproxi";
  import {mapState} from "vuex";
  import { getMediaFromId } from "../utils/apollo-parser";
  import { getFinishedAllCourseDate, getState } from '@/composables/user_log';
  import { downloadCertificateForUser } from '@/utils/pdfCertificate';

  const {CourseType} = require("../constants/game-config");

  export default {
    components: {HeaderCoproxi, ShadowButton},
    methods: {
      scrollToAnchorPoint(refName) {
        const el = this.$refs[refName.substring(1,refName.length)]
        el.scrollIntoView({ behavior: 'smooth'})
      },
      dlPdf(url) {
        window.open(this.mediaserverurl + url, '_blank');
      },
      navigateToPreviousPage(){
        this.$router.go(-1)
      },
      async onDownloadCertificateClick() {
        if (this.isLoadingCertificate) {
          return
        }
        
        this.isLoadingCertificate = true

        // Get user data
        const user = getState.value?.user

        if (user) {
          await downloadCertificateForUser(user, getFinishedAllCourseDate(), this.$t.certificate)
        }

        this.isLoadingCertificate = false
      }
    },
    computed: {
      ...mapState({
          missionFinished: state => state.gamestatus.missionFinished,
          questFinished: state => state.gamestatus.questFinished,
          courses: state => state.course.courses
      })
    },
    data () {
      return {
        quests: [],
        missions: [],
        'mediaserverurl': process.env.VUE_APP_MEDIA_URL,
        hasFinishedAllCourse: !!getFinishedAllCourseDate(),
        isLoadingCertificate: false,
      }
    },
    async mounted() {
      await this.$store.dispatch('course/getCourses', CourseType.QUEST )
      this.quests = [...this.courses[0].modules]

      await this.$store.dispatch('course/getCourses', CourseType.MISSION )
      this.missions = this.courses

      const questsObj = []
      const missionsObj = []


      const temp = this.quests[3]
      this.quests[3] = this.quests[2]
      this.quests[2] = temp

      for (var i = 0; i < this.quests.length; i++) {
        const media = []
        const module = this.quests[i]
        const medias = module.media
        for(var j = 0; j < medias.length; j++){
          const mediaFromId = await getMediaFromId(medias[j].medium_id)
          media.push(mediaFromId.data.item)
        }
          const obj = {
            id: module.id,
            title: module.title,
            media
          }
          questsObj.push(obj)
      }


      this.missions[0].modules.sort((module1, module2) => {
        if (module1.title > module2.title) {
          return 1
        } else if (module1.title < module2.title) {
          return -1
        }

        return 0
      })
      for (var t = 0; t < this.missions[0].modules.length; t++) {
        const media = []
        const module = this.missions[0].modules[t]
        const medias = module.media

        for(var y = 0; y < medias.length; y++){
          const mediaFromId = await getMediaFromId(medias[y].medium_id)
          media.push(mediaFromId.data.item)
        }
        const obj = {
          id: module.id,
          title: module.title,
          media
        }
        if(this.missionFinished.includes(module.id)){
          missionsObj.push(obj)
        }
      }
      this.quests = questsObj
      this.missions = missionsObj
      await this.$nextTick(function () {
        if(this.$route.hash){
          this.scrollToAnchorPoint(this.$route.hash)
        }
      })
      /*this.missions[0].modules.forEach( p => {
        console.log(p.title)
        p.media.forEach( p => console.log(p))
      })*/


      // Load illustrations asynchronously
     /*for (var i = 0; i < courses[0].modules.length; i++) {
        const module = courses[0].modules[i]

        if (module.metas && module.metas.thumbnail) {
          const mediaQueryResult = await getMediaFromId(module.metas.thumbnail)

          this.moduleIllustrations.push(process.env.VUE_APP_MEDIA_URL + mediaQueryResult.data.item.path)
        } else {
          this.moduleIllustrations.push('default')
        }
      }
    },
    immediate: true*/
  },
    updated: function () {

    }
  }
</script>