<template>
  <HeaderCoproxi></HeaderCoproxi>
  <section class="pl-20 pr-20 pb-20 text-left bg-white h-full">

    <header>
      <ShadowButton class="pt-4 pt-10 z-0" theme="default-grey" width="50px" @click="navigateToPreviousPage"> <img src="@/assets/icons/icon-arrow-left.svg" > </ShadowButton>
      <h2 class="font-coustard-black text-bold text-6xl text-midnight-blue">{{$t.aide.titre_aide}}</h2>
      <p class="text-blue-grey font-normal">{{$t.aide.texte_aide}}</p>
    </header>
    <div class="mt-14">
      <router-link to="/doc">
        <p class="text-midnight-blue font-black text-2xl mb-2">
          {{$t.aide.tutoriel_title}}
        </p>
      </router-link>
      <p class="text-blue-grey font-normal mb-3">
        {{$t.aide.tutoriel_text}}
      </p>
      <ShadowButton theme="default-blue-reverse" width="350px" icon="download" @click="onDownloadPDFClick">{{$t.buttons.bouton_telecharger_PDF}}</ShadowButton>
      <div>
        <p class="text-midnight-blue font-black text-2xl mt-10 mb-2">{{$t.aide.envoyer_mail}}</p>
        <a class="text-midnight-blue font-black text-2xl mt-10 mb-2 font-thin" :href="'mailto:' + $t.common.support_email">{{$t.common.support_email}}</a>
      </div>
    </div>
  </section>
</template>
<script>
import HeaderCoproxi from "../components/HeaderCoproxi";

export default {
  components: {HeaderCoproxi},
  methods: {
    navigateToPreviousPage(){
      this.$router.go(-1)
    },
    onDownloadPDFClick() {
      window.open('/GuideUtilisateurCOPROXI.pdf', '_blank')
    }
  }
}
</script>