<template>
  <div v-if="videoExpert" id="myModal" :class="`modal bg-midnight-blue flex flex-1 justify-end bg-opacity-50 ${fullscreen ? 'w-screen h-screen fullscreenmodal' : ''}`" ref="modal-content" >
    <div :class="`modal-content min-w-4/12 1480:w-4/12  h-100vh `" >
      <div :class="`p-10 bg-midnight-blue text-red h-full flex items-center`">
        <div class="container h-full flex items-center justify-center">
          <div class=" w-full flex items-center justify-center">
            <div class="w-full rounded-full flex flex-col items-center justify-center">
              <div @click="startVideo()" :class="['rounded-full h-72 w-72 mb-10 video']">
                  <video class="relative rounded-full" ref="video-player" @ended="videoendListener" :src="mediaserverurl+videoExpert.path">
                    Your browser does not support the video tag.
                  </video>
                  <div class="overlay" :class="{ show: !isPlaying, play:!isPlaying && !ended}"></div>
              </div>
              <div class="small-body-text-bold rounded-md bg-midnight-blue-light text-yellow-light p-4 w-auto text-center flex">
                <img src="@/assets/icons/icon-wave.svg" class="mr-1"><div class="whitespace-pre">{{$t.expert.nom_expert}}</div>
              </div>
              <div class="hidden" ref="videoHack" @click="startVideo()"></div>
              <ShadowButton id="startbutton" v-if="ended" class="mt-10" :disabled="!ended" @click="this.close()" :theme="`light-yellow-midnight-blue`">{{$t.buttons.bouton_continuer}}</ShadowButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ShadowButton from "../components/ShadowButton";

export default {
  emits: ["closeModal"],
  components: {
    ShadowButton
  },
  props: {
    open: {
      type: Boolean,
      default: false
    },
    fullscreen: {
      type: Boolean,
      default: false
    },
    videoExpert: {
      type: Object,
      default: function () {
        return undefined
      }
    }
  },
  data() {
    return {
      mediaserverurl: process.env.VUE_APP_MEDIA_URL,
      'videoLoaded': false,
      'isPlaying': false,
      'ended': false
    }
  },
  watch: {
    open(val) {
      if(val){
        this.openModal()
        this.eventFire(this.$refs.videoHack, 'click');
      }
     }
  },
  mounted() {
    if (this.open) {
      this.openModal();
      this.$refs['startbutton'].fireEvent('onClick')

    }
    if (this.activity) {
      this.$nextTick(() => {
        var video = this.$refs['video-player'];
        video.addEventListener(
            "canplay",
            async function () {
              this.videoLoaded = true;
              //await video.play()
              setTimeout(() =>   this.eventFire(this.$refs.videoHack, 'click'), 100)
              //video.muted = false;
              // this.isPlaying = true;
            }.bind(this)
        );
      });
    }
  },
    methods: {
      videoendListener() {
        this.isPlaying = false;
        console.log('END VIDEO')
        this.ended = true;
        //this.cleanupListeners();
      },
      startVideo() {
        if (this.isPlaying)
          return

        this.isPlaying = true;
        const video = this.$refs["video-player"];
        video.play();
      },
      openModal() {
        this.$refs["modal-content"].style.display="flex";
      },
      close() {
        // Make sure the video is stopped
        const video = this.$refs["video-player"];
        video.pause();
        this.isPlaying = false;
        this.ended = false;
        this.$refs["modal-content"].style.display="none";
        this.$emit('action', 'next')
      },
      replayVideo(){
        if(!this.isPlaying)
          this.startVideo()
      },
      eventFire(el, etype){
        if (el.fireEvent) {
          el.fireEvent('on' + etype);
        } else {
          var evObj = document.createEvent('Events');
          evObj.initEvent(etype, true, false);
          el.dispatchEvent(evObj);
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
/* Add padding and center-align text to the container */
.container {
  text-align: center;
}

/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
}
/* Style the horizontal ruler */
hr {
  border: 1px solid #f1f1f1;
  margin-bottom: 25px;
}

/* Clear floats */
.clearfix::after {
  content: "";
  clear: both;
  display: table;
}
#myModal{
  z-index:6;
}

.video {
  position: relative;
  border-radius: 9999px;
}
.video .overlay {
  content:'';
  opacity: 0;
}
.video .overlay.show {
  content:'';
  position: absolute;
  top:0;
  bottom: 0;
  left: 0;
  right: 0;
  cursor: pointer;
  background-image: url("~@/assets/icons/illus-relancer.svg");
  background-repeat: no-repeat;
  background-position: center center;
  opacity: 1;
  transition: opacity 0.5s ease;
  @apply rounded-full flex h-full w-full bg-midnight-blue bg-opacity-50;
}

.video .overlay.play {
  background-image: url("~@/assets/icons/illus-play.svg");
}

/* Change styles for cancel button and delete button on extra small screens */
@media screen and (max-width: 300px) {
  .cancelbtn, .deletebtn {
    width: 100%;
  }
}
</style>
